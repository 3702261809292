import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Outlet } from 'react-router-dom';

import { styled, useTheme } from '@mui/material/styles';
import { AppBar, Box, CssBaseline, Toolbar, useMediaQuery, Stack, Typography, Link } from '@mui/material';

// project imports
import Breadcrumbs from 'ui-component/extended/Breadcrumbs';
import Header from './Header';
import Sidebar from './Sidebar';
import Customization from '../Customization';
import navigation from 'menu-items';
import { drawerWidth } from 'store/constant';
import { SET_MENU } from 'store/actions';

// assets
import { IconChevronRight } from '@tabler/icons';
import LayautContext from 'Context/ContextLayaut';
// llamada servidor
import axios from 'axios';
import LoginContext from 'Context/ContextLogin';
import { makeStyles } from '@mui/styles';
import { ModalFormularioV2, AvisoConfirmacion } from 'ui-component';
import { height } from '@mui/system';

// styles
const Main = styled('main', { shouldForwardProp: (prop) => prop !== 'open' })(({ theme, open }) => ({
    ...theme.typography.mainContent,
    ...(!open && {
        borderBottomLeftRadius: 0,
        borderBottomRightRadius: 0,
        transition: theme.transitions.create('margin', {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.leavingScreen
        }),
        [theme.breakpoints.up('md')]: {
            marginLeft: -(drawerWidth - 20),
            width: `calc(100% - ${drawerWidth}px)`
        },
        [theme.breakpoints.down('md')]: {
            marginLeft: '20px',
            width: `calc(100% - ${drawerWidth}px)`,
            padding: '16px'
        },
        [theme.breakpoints.down('sm')]: {
            marginLeft: '10px',
            width: `calc(100% - ${drawerWidth}px)`,
            padding: '0px',
            marginRight: '10px'
        }
    }),
    ...(open && {
        transition: theme.transitions.create('margin', {
            easing: theme.transitions.easing.easeOut,
            duration: theme.transitions.duration.enteringScreen
        }),
        marginLeft: 0,
        borderBottomLeftRadius: 0,
        borderBottomRightRadius: 0,
        width: `calc(100% - ${drawerWidth}px)`,
        [theme.breakpoints.down('md')]: {
            marginLeft: '20px'
        },
        [theme.breakpoints.down('sm')]: {
            marginLeft: '10px'
        }
    })
}));
const useStyle = makeStyles((theme) => ({
    cargando: {
        zIndex: '1600'
    }
}));
// configuracion del axios
const iaxios = axios.create({
    // baseURL: 'http://localhost/DTOUniversity/index.php/'
    baseURL: 'https://universidadmaderas.com/buniversity/index.php/'
    // baseURL: 'https://universidad.gphsis.com/test/buniversity/index.php/'
});
// ==============================|| MAIN LAYOUT ||============================== //

const MainLayout = ({ rutas }) => {
    const theme = useTheme();
    const classes = useStyle();
    const matchDownMd = useMediaQuery(theme.breakpoints.down('lg'));
    const [cargando, setCargando] = useState(false);
    const [modalAddEditUser, setModalAddEditUser] = useState({
        open: false,
        titulo: '',
        isVer: false,
        mensajeConfirmacion: undefined,
        formulario: undefined,
        propsForm: null,
        width: 'xs',
        aceptar: () => {}
    });

    // Handle left drawer
    const leftDrawerOpened = useSelector((state) => state.customization.opened);
    const dispatch = useDispatch();
    const handleLeftDrawerToggle = () => {
        dispatch({ type: SET_MENU, opened: !leftDrawerOpened });
    };

    const cerrarModalForm = (event) => setModalAddEditUser({ ...modalAddEditUser, open: false });
    // configuracion del axios
    useEffect(() => {
        dispatch({ type: SET_MENU, opened: !matchDownMd });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [matchDownMd]);

    return (
        <>
            <Box sx={{ display: 'flex' }}>
                <CssBaseline />
                {/* INICIA HEADER */}
                <AppBar
                    enableColorOnDark
                    position="fixed"
                    color="inherit"
                    elevation={0}
                    sx={{
                        bgcolor: theme.palette.background.default,
                        transition: leftDrawerOpened ? theme.transitions.create('width') : 'none'
                    }}
                >
                    <Toolbar sx={{ backgroundColor: theme.palette.background.default }}>
                        <Header handleLeftDrawerToggle={handleLeftDrawerToggle} />
                    </Toolbar>
                </AppBar>
                {/* TERMINA HEADER */}
                {/* INICIA MENU LATERAL */}
                <Sidebar drawerOpen={leftDrawerOpened} rutas={rutas} drawerToggle={handleLeftDrawerToggle} />
                {/* TERMINA MENÚ LATERAL */}
                {/* INICIA CONTENIDO PRICIPAL */}
                <Main theme={theme} open={leftDrawerOpened}>
                    {/* breadcrumb */}
                    <Breadcrumbs separator={IconChevronRight} navigation={navigation} icon title rightAlign />
                    <Outlet />
                </Main>
                {/* TERMINA CONTENIDO PRINCIPAL */}

                {/* importar ruedita de pantalla pricinpal */}
                {/* <Customization /> */}
            </Box>
            <ModalFormularioV2
                titulo={modalAddEditUser.titulo}
                open={modalAddEditUser.open}
                isVer={modalAddEditUser.isVer}
                mensajeConfirmacion={modalAddEditUser.mensajeConfirmacion}
                bloqueo={cargando}
                width={modalAddEditUser.width}
                OnCancelar={() => cerrarModalForm()}
                aceptar={modalAddEditUser.aceptar}
                btnAceptarName={modalAddEditUser?.btnAceptarName}
                btnCancelarName={modalAddEditUser?.btnCancelarName}
            >
                {modalAddEditUser.formulario && <modalAddEditUser.formulario {...modalAddEditUser.propsForm} />}
            </ModalFormularioV2>
        </>
    );
};

const MainLayoutNew = ({ rutas }) => {
    const theme = useTheme();
    const classes = useStyle();
    const matchDownMd = useMediaQuery(theme.breakpoints.down('lg'));
    const [cargando, setCargando] = useState(false);
    const [modalAddEditUser, setModalAddEditUser] = useState({
        open: false,
        titulo: '',
        isVer: false,
        mensajeConfirmacion: undefined,
        formulario: undefined,
        propsForm: null,
        width: 'xs',
        aceptar: () => {}
    });

    // Handle left drawer
    const leftDrawerOpened = useSelector((state) => state.customization.opened);
    const dispatch = useDispatch();
    const handleLeftDrawerToggle = () => {
        dispatch({ type: SET_MENU, opened: !leftDrawerOpened });
    };

    const cerrarModalForm = (event) => setModalAddEditUser({ ...modalAddEditUser, open: false });
    // configuracion del axios
    useEffect(() => {
        dispatch({ type: SET_MENU, opened: !matchDownMd });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [matchDownMd]);

    return (
        <>
            {/* ESTE ES EL 100% DE LA PAGINA, TIENE QUE MANTENERSE FIJO PARA EVITAR EL OVERFLOW INECESARIO */}
            <Box style={{ height: '100vh', maxHeight: '100vh', with: '100vw', overflow: 'hidden' }}>
                {/* INICIA MENU LATERAL */}
                <Sidebar drawerOpen={leftDrawerOpened} rutas={rutas} drawerToggle={handleLeftDrawerToggle} />
                {/* TERMINA MENÚ LATERAL */}
                {/* INICIA HEADER, NECESITAMOS DEJARLO A UNA ALTURA DEFINIDA Y EN LA PARTE SUPERIOR DE LA PANTALLA */}
                <Box
                    sx={{
                        height: {
                            xs: '140px',
                            sm: '95px'
                        }
                    }}
                    width="100%"
                    style={{}}
                >
                    <AppBar
                        enableColorOnDark
                        elevation={0}
                        color="inherit"
                        sx={{
                            bgcolor: theme.palette.background.default,
                            transition: leftDrawerOpened ? theme.transitions.create('width') : 'none'
                        }}
                        style={{
                            position: 'relative',
                            height: '100%',
                            width: '100%',
                            backgroundColor: '#FFFFF',
                            background: 'transparent'
                        }}
                    >
                        <Toolbar className="header" sx={{}} style={{ backgroundColor: 'rgb(255, 255, 255, 0.93)' }}>
                            <Header handleLeftDrawerToggle={handleLeftDrawerToggle} drawerOpen={leftDrawerOpened} />
                        </Toolbar>
                    </AppBar>
                </Box>
                {/* TERMINA HEADER */}
                {/* INICIA CONTENIDO DE APP */}
                <Box
                    sx={{
                        maxHeight: {
                            xs: '75vh',
                            sm: '85vh'
                        }
                    }}
                    className="body"
                    style={{ height: '100%', overflowX: 'none' }}
                >
                    {/* <Breadcrumbs separator={IconChevronRight} navigation={navigation} icon title rightAlign /> */}
                    <Box style={{ height: '100%' }}>
                        {/* VISTAS DE APLICACION */}
                        <Outlet />
                    </Box>
                </Box>
                {/* TERMINA CONTENIDO DE APP */}
                {/* INICIA FOOTER */}
                <Box
                    className="footer2"
                    style={{
                        display: 'flex',
                        position: 'relative',
                        zIndex: '1202',
                        with: '100%',
                        height: '95px',
                        backgroundColor: '#FAFAFA'
                    }}
                >
                    <Typography
                        margin="10px 0px 15px 20px"
                        variant="body2"
                        color="black"
                        component={Link}
                        href="https://ciudadmaderas.com/"
                        target="_blank"
                        underline="hover"
                    >
                        Copyright&copy; 2020 Ciudad Maderas. Todos los derechos reservados.
                    </Typography>
                </Box>
                {/* TERMINA CONTENIDO DE APP */}
            </Box>
            <ModalFormularioV2
                titulo={modalAddEditUser.titulo}
                open={modalAddEditUser.open}
                isVer={modalAddEditUser.isVer}
                mensajeConfirmacion={modalAddEditUser.mensajeConfirmacion}
                bloqueo={cargando}
                width={modalAddEditUser.width}
                OnCancelar={() => cerrarModalForm()}
                aceptar={modalAddEditUser.aceptar}
                btnAceptarName={modalAddEditUser?.btnAceptarName}
                btnCancelarName={modalAddEditUser?.btnCancelarName}
            >
                {modalAddEditUser.formulario && <modalAddEditUser.formulario {...modalAddEditUser.propsForm} />}
            </ModalFormularioV2>
        </>
    );
};

export default MainLayoutNew;
