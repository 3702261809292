// material-ui
import { Typography, Box, ButtonBase } from '@mui/material';
import { Facebook, YouTube, LinkedIn, Instagram } from '@mui/icons-material';
import { makeStyles, styled } from '@mui/styles';
// project imports
import NavGroup from './NavGroup';
import pages from 'menu-items/pages';

import logoFace from 'assets/images/facebook-icon.svg';
import logoX from 'assets/images/twitter-icon.svg';
import logoInsta from 'assets/images/instagram-icon.svg';
import logoYou from 'assets/images/youtube-icon.svg';
import logoLin from 'assets/images/indeed-icon.svg';
import logoTik from 'assets/images/tiktok-icon.svg';

const useStyles = makeStyles((theme) => ({
    styleIcono: {
        width: '28px',
        height: '28px',
        color: theme.palette.dark.main,
        '&:hover': {
            color: theme.palette.btn.blue
        }
    },
    icon: {
        fill: theme.palette.dark.main,
        '&:hover': {
            fill: theme.palette.btn.blue
        }
    }
}));

// ==============================|| SIDEBAR MENU LIST ||============================== //
const TikTokIcon = (props) => {
    const classes = useStyles();
    return (
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 50 50" width="24px" height="24px" className={classes.icon}>
            <path d="M41,4H9C6.243,4,4,6.243,4,9v32c0,2.757,2.243,5,5,5h32c2.757,0,5-2.243,5-5V9C46,6.243,43.757,4,41,4z M37.006,22.323 c-0.227,0.021-0.457,0.035-0.69,0.035c-2.623,0-4.928-1.349-6.269-3.388c0,5.349,0,11.435,0,11.537c0,4.709-3.818,8.527-8.527,8.527 s-8.527-3.818-8.527-8.527s3.818-8.527,8.527-8.527c0.178,0,0.352,0.016,0.527,0.027v4.202c-0.175-0.021-0.347-0.053-0.527-0.053 c-2.404,0-4.352,1.948-4.352,4.352s1.948,4.352,4.352,4.352s4.527-1.894,4.527-4.298c0-0.095,0.042-19.594,0.042-19.594h4.016 c0.378,3.591,3.277,6.425,6.901,6.685V22.323z" />
        </svg>
    );
};

const TwitterIcon = (props) => {
    const classes = useStyles();
    return (
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512" width="24px" height="24px" className={classes.icon}>
            <path d="M389.2 48h70.6L305.6 224.2 487 464H345L233.7 318.6 106.5 464H35.8L200.7 275.5 26.8 48H172.4L272.9 180.9 389.2 48zM364.4 421.8h39.1L151.1 88h-42L364.4 421.8z" />
        </svg>
    );
};

const GeneralIcon = (props) => {
    const classes = useStyles();
    return <img src={props.logo} alt="Berry" width="30px" />;
};

const MenuList = ({ rutas, drawerToggle }) => {
    const classes = useStyles();
    const navItems = [pages(rutas)].map((item) => {
        switch (item.type) {
            case 'group':
                return <NavGroup drawerToggle={drawerToggle} key={item.id} item={item} />;
            default:
                return (
                    <Typography key={item.id} variant="h6" color="error" align="center">
                        Menu Items Error
                    </Typography>
                );
        }
    });

    return (
        <>
            {navItems}
            <Box sx={{ display: 'flex', alignItems: 'center', p: 2, mx: 'auto' }}>
                <a href="https://www.facebook.com/CiudadMaderasSitioOficial" target="_blank" rel="noreferrer">
                    <GeneralIcon logo={logoFace} />
                </a>
                <a href="https://twitter.com/cd_maderas" target="_blank" rel="noreferrer">
                    <GeneralIcon logo={logoX} />
                </a>
                <a href="https://www.instagram.com/ciudad.maderas/" target="_blank" rel="noreferrer">
                    <GeneralIcon logo={logoInsta} />
                </a>
                <a href="https://www.linkedin.com/company/ciudadmaderas" target="_blank" rel="noreferrer">
                    <GeneralIcon logo={logoLin} />
                </a>
                <a href="https://www.tiktok.com/@ciudadmaderas" target="_blank" rel="noreferrer">
                    <GeneralIcon logo={logoTik} />
                </a>
                <a href="https://www.youtube.com/@CiudadMaderasOficial" target="_blank" rel="noreferrer">
                    <GeneralIcon logo={logoYou} />
                </a>
            </Box>
        </>
    );
};

export default MenuList;
