function FechaHoraMX(date, tipo, tipoMes = 's') {
    /* s ='' */
    /* tipo 1 = fecha, 2=fecha hora, 3=Hoy, 4 FECHA HORA 24 5=d/m/a hora */
    const meses = ['Ene', 'Feb', 'Mar', 'Abr', 'May', 'Jun', 'Jul', 'Ago', 'Sep', 'Oct', 'Nov', 'Dic'];
    const mesesLargo = [
        'Enero',
        'Febrero',
        'Marzo',
        'Abril',
        'Mayo',
        'Junio',
        'Julio',
        'Agosto',
        'Septiembre',
        'Octubre',
        'Noviembre',
        'Diciembre'
    ];
    const d = tipo != 3 ? new Date(date) : new Date();
    let day = `${d.getDate()}`;
    const year = d.getFullYear();
    let mesFecha = d.getMonth();
    switch (tipoMes) {
        case 's':
            mesFecha = meses[mesFecha];
            break;
        case 'l':
            mesFecha = mesesLargo[mesFecha];
            break;
        default:
            mesFecha = mesFecha <= 9 ? `0${mesFecha + 1}` : mesFecha + 1;
    }
    if (day.length < 2) day = `0${day}`;
    if (tipo == 1 || tipo == 3) return [day, mesFecha, year].join('-');
    if (tipo == 2) {
        let hours = d.getHours();
        let minutes = d.getMinutes();
        const ampm = hours >= 12 ? 'PM' : 'AM';
        hours = hours % 12 ? hours % 12 : 12; // the hour '0' should be '12'
        minutes = minutes < 10 ? `0${minutes}` : minutes;
        return `${[day, mesFecha, year].join('-')} ${hours.toString().padStart(2, '0')}:${minutes} ${ampm}`;
    }
    if (tipo == 4) {
        const hours = d.getHours().toString().padStart(2, '0');
        const minutes = d.getMinutes().toString().padStart(2, '0');
        return `${[day, mesFecha, year].join('-')} ${hours}:${minutes}`;
    }
    if (tipo == 5) {
        const hours = (d.getHours() % 12 || 12).toString().padStart(2, '0'); // the hour '0' should be '12';
        const minutes = d.getMinutes().toString().padStart(2, '0');
        const ampm = d.getHours() ? 'PM' : 'AM';
        return `${[day, mesFecha, year].join('-')} ${hours}:${minutes} ${ampm}`;
    }
    if (tipo == 6) {
        return [year, mesFecha, day].join('-');
    }
}

export default FechaHoraMX;
