// material-ui
import {
    Container,
    FormControl,
    FormLabel,
    Grid,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Typography
} from '@mui/material';
import { styled } from '@mui/material/styles';
import { useState, useEffect } from 'react';
import { FormatNumber } from 'ui-component';

import MainCard from 'ui-component/cards/MainCard';
import SubCard from 'ui-component/cards/SubCard';
import FechaHoraMX from 'utils/FechaHoraMX';

import ServiciosPagosLinea from 'utils/ServiciosPagosLinea';
import { BuscarLote, FormPago } from './components';
// ==============================|| SAMPLE PAGE ||============================== //
const CssTable = styled(Table)(({ theme }) => ({
    '& .MuiTableCell-root': {
        borderBottom: 'none',
        paddingBottom: '0',
        paddingTop: '0'
    },
    '& .MuiTableBody-root': {
        '& .MuiTableRow-root': {
            '&:nth-child(odd)': {
                backgroundColor: theme.palette.action.hover
            }
        }
    }
}));
const PagosLinea = () => {
    const fechaActual = FechaHoraMX(new Date(), 6, 'n');
    const [infoLote, setInfoLote] = useState({ plan: [] });
    const [esVencidos, setVencidos] = useState(false);
    const [arrPendientes, setArrPendientes] = useState([]);
    const serviciosPagosLinea = ServiciosPagosLinea();
    const BuscarTerreno = (lote) => {
        serviciosPagosLinea.traerInfoLote(
            (respuesta) => {
                setInfoLote({ ...lote, ...respuesta.lote, minCapital: respuesta.minCapital });
            },
            { idCobranza: lote.idCobranza, baseneodata: lote.baseneodata },
            () => setInfoLote({ plan: [] })
        );
    };

    useEffect(() => {
        if (infoLote && infoLote != null && infoLote.plan) {
            const pendientes = [];
            let ven = 0;
            let pend = 0;
            for (let i = 0; i < infoLote.plan.length; i += 1) {
                const mensualidad = infoLote.plan[i];
                if (mensualidad.fechaplan < fechaActual) {
                    ven += 1;
                } else if (pend <= 12) {
                    pendientes.push(infoLote.plan[i]);
                    pend += 1;
                }
            }

            setArrPendientes(pendientes);

            if (ven > 0) {
                setVencidos(true);
            }
        }
    }, [infoLote]);

    return (
        <MainCard title="Pago en línea" className="bg-gray">
            <Grid container className="mainContainer">
                <Grid item xs={12} md={9} className="cardContainer" padding="0px!important">
                    <Grid className="body">
                        <Grid container spacing={2} padding="0px 10px">
                            <Grid item xs={12} container direction="column" spacing={3}>
                                <Grid item>
                                    <BuscarLote infoLote={infoLote} fechaActual={fechaActual} actualizarTerreno={BuscarTerreno} />
                                </Grid>
                            </Grid>
                            <Grid item xs={12} container direction="column" spacing={3}>
                                {infoLote.idCobranza && (
                                    <Grid item>
                                        <SubCard title="Información general">
                                            <CssTable>
                                                <TableHead>
                                                    <TableRow>
                                                        <TableCell>Proyecto</TableCell>
                                                        <TableCell>Contrato</TableCell>
                                                        <TableCell>Vivienda</TableCell>
                                                        <TableCell>Referencia</TableCell>
                                                        <TableCell>Fecha de corte</TableCell>
                                                        <TableCell>Mensualidad</TableCell>
                                                    </TableRow>
                                                </TableHead>

                                                <TableBody>
                                                    <TableCell>{infoLote.nproyecto}</TableCell>
                                                    <TableCell>{infoLote.numcliente}</TableCell>
                                                    <TableCell>{infoLote.vivienda}</TableCell>
                                                    <TableCell>{infoLote.referencia}</TableCell>
                                                    <TableCell>{`EL DÍA ${infoLote.fechaplan} DE CADA MES`}</TableCell>
                                                    <TableCell>
                                                        <FormatNumber type="money" value={infoLote.mensualidad} />
                                                    </TableCell>
                                                </TableBody>
                                            </CssTable>
                                        </SubCard>
                                    </Grid>
                                )}

                                {esVencidos == true && (
                                    <Grid item>
                                        <SubCard title="Pagos vencidos">
                                            <CssTable>
                                                <TableHead>
                                                    <TableRow>
                                                        <TableCell>Fecha</TableCell>
                                                        <TableCell>Periodo vencido </TableCell>
                                                        <TableCell>Mensualidad</TableCell>
                                                        <TableCell>Moratorios Total a pagar</TableCell>
                                                        <TableCell>Total a pagar</TableCell>
                                                    </TableRow>
                                                </TableHead>
                                                {infoLote.plan?.length && (
                                                    <TableBody>
                                                        {infoLote.plan
                                                            .filter((mensualidad) => mensualidad.fechaplan < fechaActual)
                                                            .map((mensualidad) => (
                                                                <TableRow>
                                                                    <TableCell>{FechaHoraMX(mensualidad.fechaplan, 1, 's')}</TableCell>
                                                                    <TableCell>{mensualidad.dias}</TableCell>
                                                                    <TableCell>
                                                                        <FormatNumber type="money" value={mensualidad.mensualidad} />
                                                                    </TableCell>
                                                                    <TableCell>
                                                                        <FormatNumber type="money" value={mensualidad.interes_moratorio} />
                                                                    </TableCell>
                                                                    <TableCell>
                                                                        <FormatNumber
                                                                            type="money"
                                                                            value={
                                                                                Number(mensualidad.mensualidad) +
                                                                                Number(mensualidad.interes_moratorio)
                                                                            }
                                                                        />
                                                                    </TableCell>
                                                                </TableRow>
                                                            ))}
                                                    </TableBody>
                                                )}
                                            </CssTable>
                                        </SubCard>
                                    </Grid>
                                )}

                                {esVencidos == false && (
                                    <Grid item>
                                        <SubCard title="Pagos siguientes">
                                            <CssTable>
                                                <TableHead>
                                                    <TableRow>
                                                        <TableCell>Fecha</TableCell>
                                                        <TableCell>Mensualidad</TableCell>
                                                        <TableCell>Moratorios</TableCell>
                                                        <TableCell>Total a pagar</TableCell>
                                                    </TableRow>
                                                </TableHead>
                                                {infoLote.plan?.length && (
                                                    <TableBody>
                                                        {arrPendientes.map((mensualidad) => (
                                                            <TableRow>
                                                                <TableCell>{FechaHoraMX(mensualidad.fechaplan, 1, 's')}</TableCell>
                                                                <TableCell>
                                                                    <FormatNumber type="money" value={mensualidad.mensualidad} />
                                                                </TableCell>
                                                                <TableCell>
                                                                    <FormatNumber type="money" value={mensualidad.interes_moratorio} />
                                                                </TableCell>
                                                                <TableCell>
                                                                    <FormatNumber
                                                                        type="money"
                                                                        value={
                                                                            Number(mensualidad.mensualidad) +
                                                                            Number(mensualidad.interes_moratorio)
                                                                        }
                                                                    />
                                                                </TableCell>
                                                            </TableRow>
                                                        ))}
                                                    </TableBody>
                                                )}
                                            </CssTable>
                                        </SubCard>
                                    </Grid>
                                )}
                            </Grid>
                            {infoLote.idCobranza && (
                                <Grid item xs={12} container direction="column" spacing={3}>
                                    <Grid item>
                                        <FormPago
                                            infoLote={infoLote}
                                            fechaActual={fechaActual}
                                            actualizarTerreno={BuscarTerreno}
                                            esVencidos={esVencidos}
                                        />
                                    </Grid>
                                </Grid>
                            )}
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
            {/* <Grid container spacing={3}>
                <Grid item xs={12} md={4} container direction="column" spacing={3}>
                    <Grid item>
                        <FormPago infoLote={infoLote} fechaActual={fechaActual} actualizarTerreno={BuscarTerreno} />
                    </Grid>
                </Grid>
                <Grid item xs={12} md={8} container direction="column" spacing={3}>
                    <Grid item>
                        <SubCard title="Información general">
                            <CssTable>
                                <TableHead>
                                    <TableRow>
                                        <TableCell>Proyecto</TableCell>
                                        <TableCell>Contrato</TableCell>
                                        <TableCell>Vivienda</TableCell>
                                        <TableCell>Referencia</TableCell>
                                        <TableCell>Fecha de corte</TableCell>
                                        <TableCell>Mensualidad</TableCell>
                                    </TableRow>
                                </TableHead>
                                {infoLote.idCobranza && (
                                    <TableBody>
                                        <TableCell>{infoLote.nproyecto}</TableCell>
                                        <TableCell>{infoLote.numcliente}</TableCell>
                                        <TableCell>{infoLote.vivienda}</TableCell>
                                        <TableCell>{infoLote.referencia}</TableCell>
                                        <TableCell>{`EL DÍA ${infoLote.fechaplan} DE CADA MES`}</TableCell>
                                        <TableCell>
                                            <FormatNumber type="money" value={infoLote.mensualidad} />
                                        </TableCell>
                                    </TableBody>
                                )}
                            </CssTable>
                        </SubCard>
                    </Grid>
                    <Grid item>
                        <SubCard title="Pagos vencidos">
                            <CssTable>
                                <TableHead>
                                    <TableRow>
                                        <TableCell>Fecha</TableCell>
                                        <TableCell>Periodo vencido </TableCell>
                                        <TableCell>Mensualidad</TableCell>
                                        <TableCell>Moratorios Total a pagar</TableCell>
                                        <TableCell>Total a pagar</TableCell>
                                    </TableRow>
                                </TableHead>
                                {infoLote.plan?.length && (
                                    <TableBody>
                                        {infoLote.plan
                                            .filter((mensualidad) => mensualidad.fechaplan < fechaActual)
                                            .map((mensualidad) => (
                                                <TableRow>
                                                    <TableCell>{FechaHoraMX(mensualidad.fechaplan, 1, 's')}</TableCell>
                                                    <TableCell>{mensualidad.dias}</TableCell>
                                                    <TableCell>
                                                        <FormatNumber type="money" value={mensualidad.mensualidad} />
                                                    </TableCell>
                                                    <TableCell>
                                                        <FormatNumber type="money" value={mensualidad.interes_moratorio} />
                                                    </TableCell>
                                                    <TableCell>
                                                        <FormatNumber
                                                            type="money"
                                                            value={Number(mensualidad.mensualidad) + Number(mensualidad.interes_moratorio)}
                                                        />
                                                    </TableCell>
                                                </TableRow>
                                            ))}
                                    </TableBody>
                                )}
                            </CssTable>
                        </TableContainer>
                    </SubCard>
                </Grid>
                <Grid item xs={12} sm={10} xl={8}>
                    <SubCard title="Pagos siguientes">
                        <CssTable>
                            <TableHead>
                                <TableRow>
                                    <TableCell>Fecha</TableCell>
                                    <TableCell>Mensualidad</TableCell>
                                    <TableCell>Total a pagar</TableCell>
                                </TableRow>
                            </TableHead>
                            {infoLote.plan?.length && (
                                <TableBody>
                                    {infoLote.plan
                                        .filter((mensualidad) => mensualidad.fechaplan >= fechaActual)
                                        .map((mensualidad) => (
                                            <TableRow>
                                                <TableCell>{FechaHoraMX(mensualidad.fechaplan, 1, 's')}</TableCell>
                                                <TableCell>
                                                    <FormatNumber type="money" value={mensualidad.mensualidad} />
                                                </TableCell>
                                                <TableCell>
                                                    <FormatNumber
                                                        type="money"
                                                        value={Number(mensualidad.mensualidad) + Number(mensualidad.interes_moratorio)}
                                                    />
                                                </TableCell>
                                            </TableRow>
                                        ))}
                                </TableBody>
                            )}
                        </CssTable>
                    </SubCard>
                </Grid>
                <Grid item xs={12} sm={10} xl={8}>
                    <FormPago infoLote={infoLote} fechaActual={fechaActual} actualizarTerreno={BuscarTerreno} />
                </Grid>
            </Grid> */}
        </MainCard>
    );
};
export default PagosLinea;
