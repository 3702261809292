import React, { useRef } from 'react';
import { Controller } from 'react-hook-form';
import PropTypes from 'prop-types';
import { styled } from '@mui/material/styles';
import { Menu, MenuItem } from '@mui/material';

const SelectImage = styled('div')(({ theme }) => ({
    '& #a': {
        paddingLeft: '0px'
    } /*
    '& #a img, .btn-select img': {
        height: '30px'
    },
    */,

    '& #a li': {
        listStyle: 'none',
        paddingTop: '5px',
        paddingBottom: '5px'
    },
    '& #a li:hover': {
        backgroundColor: '#F4F3F3'
    },
    '& #a li img': {
        margin: '5px'
    },
    '& #a li span, .btn-select li span': {
        marginLeft: '30px'
    },
    /* item list */
    '& .b': {},
    '& .open': {
        display: 'show !important'
    },
    '& .btn-select': {
        marginTop: '10px',
        width: '100%',
        maxWidth: '350px',
        height: '34px',
        borderRadius: '5px',
        backgroundColor: '#fff',
        border: '1px solid #ccc'
    },
    '& .btn-select li': {
        listStyle: 'none',
        float: 'left',
        paddingBottom: ' 0px'
    },
    '& .btn-select:hover li': {
        marginLeft: '0px'
    },
    '& .btn-select:hover': {
        backgroundColor: '#F4F3F3',
        border: '1px solid transparent',
        boxShadow: 'inset 0 0px 0px 1px #ccc'
    },
    '& .btn-select:focus': {
        outline: 'none'
    },
    '& .lang-select': {
        marginLeft: '50px'
    }
}));
const TextFieldSelectImage = (props) => {
    const { name, label, rules, defaultValue, control, onChange } = props;
    const lstOpciones = useRef();
    return (
        <Controller
            defaultValue={defaultValue}
            rules={rules}
            name={name}
            control={control}
            render={({ field, fieldState: { error } }) => (
                <SelectImage>
                    <button
                        className="btn-select"
                        value=""
                        type="button"
                        onClick={(event) => {
                            lstOpciones.current.style = 'display: block;';
                        }}
                    />
                    <div
                        className="b MuiPaper-root MuiPaper-elevation MuiPaper-rounded MuiPaper-elevation0 MuiMenu-paper MuiPaper-root MuiPaper-elevation MuiPaper-rounded MuiPaper-elevation8 MuiPopover-paper css-1ku04m1-MuiPaper-root-MuiMenu-paper-MuiPaper-root-MuiPopover-paper"
                        ref={lstOpciones}
                    >
                        <ul id="a MuiList-root MuiList-padding MuiMenu-list css-6hp17o-MuiList-root-MuiMenu-list">
                            <li>
                                <img src="https://pagosciudadmaderas.com/pago/images/banbajio.png" alt="" value="en" height="25px" />
                                <span />
                            </li>
                            <li>
                                <img src="https://pagosciudadmaderas.com/pago/images/BBVA-logo.png" alt="" value="au" height="30px" />
                                <span />
                            </li>
                        </ul>
                    </div>
                </SelectImage>
            )}
        />
    );
};
TextFieldSelectImage.propTypes = {
    name: PropTypes.string.isRequired,
    rules: PropTypes.object,
    label: PropTypes.string.isRequired,
    defaultValue: PropTypes.any,
    control: PropTypes.any,
    onChange: PropTypes.func
};
export default TextFieldSelectImage;

/*
<div className="b" ref={lstOpciones}>
                        <ul id="a">
                            <li>
                                <img src="https://pagosciudadmaderas.com/pago/images/banbajio.png" alt="" value="en" height="25px" />
                                <span />
                            </li>
                            <li>
                                <img src="https://pagosciudadmaderas.com/pago/images/BBVA-logo.png" alt="" value="au" height="30px" />
                                <span />
                            </li>
                        </ul>
                    </div>

<Menu
                        id="basic-menu"
                        anchorEl={lstOpciones}
                        open
                        MenuListProps={{
                            'aria-labelledby': 'basic-button'
                        }}
                    >
                        <MenuItem>
                            <img src="https://pagosciudadmaderas.com/pago/images/banbajio.png" alt="" value="en" height="25px" />
                        </MenuItem>
                        <MenuItem>
                            <img src="https://pagosciudadmaderas.com/pago/images/BBVA-logo.png" alt="" value="en" height="25px" />
                        </MenuItem>
                    </Menu>
*/
