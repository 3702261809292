// material-ui
import React, { useState, useEffect } from 'react';
import { Typography, Grid, TextField, InputAdornment, FormControl, FormControlLabel, RadioGroup, Radio, Button } from '@mui/material';
import { ArrowDropDown } from '@mui/icons-material';
// project imports
import { TextFieldAutoComplete } from 'ui-component';
import MainCard from 'ui-component/cards/MainCard';
import useServiciosFacturas from 'utils/ServiciosFacturas';
import Tabla from 'ui-component/Tabla';
import JSZip from 'jszip';
import { saveAs } from 'file-saver';
import { useForm } from 'react-hook-form';
import LoginContext, { useLogin } from 'Context/ContextLogin';

// ==============================|| SAMPLE PAGE ||============================== //

const Facturas = () => {
    const mesesLargo = [
        'Enero',
        'Febrero',
        'Marzo',
        'Abril',
        'Mayo',
        'Junio',
        'Julio',
        'Agosto',
        'Septiembre',
        'Octubre',
        'Noviembre',
        'Diciembre'
    ];
    const {
        register,
        control,
        handleSubmit,
        formState: { errors }
    } = useForm();
    const { login } = useLogin();

    const [vivienda, setVivienda] = useState(null);
    const [fechas, setFechas] = useState({
        yearActual: 2024,
        yearSiguiente: 2024
    });
    const [muestraError, setError] = useState(false);
    const [clientes, SetClientes] = useState([]);
    const ServiciosFacturas = useServiciosFacturas();
    const clickConsultar = (event) => {
        setError(false);
        setVivienda(event.proyecto);
        ServiciosFacturas.GetFacturas(
            (facturas) => {
                SetClientes(facturas);
                if (!facturas || facturas.length <= 0) {
                    setError(true);
                } else {
                    setError(false);
                }
            },
            { vivienda: event.proyecto.idCobranza, fecInicial: fechas.fechaInicio, fecFinal: fechas.fechaFin }
        );
    };

    const clickDescargar = (item) => {
        if (vivienda && vivienda != null) {
            ServiciosFacturas.getXML(
                (facturas) => {
                    const binaryString = window.atob(facturas.pdf);
                    const binaryLen = binaryString.length;
                    const bytes = new Uint8Array(binaryLen);

                    for (let i = 0; i < binaryLen; i += 1) {
                        const ascii = binaryString.charCodeAt(i);
                        bytes[i] = ascii;
                    }

                    const blob1 = new Blob([bytes], { type: 'application/pdf' });
                    const zip = new JSZip();

                    zip.file(`${facturas.nombre}.pdf`, blob1);
                    zip.file(`${facturas.nombre}.xml`, window.atob(facturas.xml));
                    zip.generateAsync({ type: 'blob' }).then((blob) => {
                        saveAs(blob, `${facturas.nombre}.zip`);
                    });
                },
                {
                    idcobranza: vivienda.idCobranza,
                    XMLCFD: item.XMLCFD,
                    fecInicial: fechas.fechaInicio,
                    fecFinal: fechas.fechaFin
                }
            );
        }
    };
    const accionesFila = [{ label: 'DESCARGAR', onClick: clickDescargar, enabled: (item) => true, id: 'btn-descargar-factura' }];
    const columns = [
        { label: 'FOLIO FISCAL', name: 'foliofiscalcfdi', filtrar: true },
        { name: 'fechafactura', label: 'FECHA EMISIÓN', filtrar: true, type: { opcion: 'date', accion: 1 } }
    ];

    useEffect(() => {
        const actual = new Date();
        const anterior = new Date();
        anterior.setMonth(anterior.getMonth() - 13);

        const texto1 = `${mesesLargo[actual.getMonth()]} de ${actual.getFullYear()}`;
        const mes1 = actual.getMonth() + 1;
        const numMes1 = mes1 > 9 ? mes1 : `0${mes1}`;
        const fechaActual = `${actual.getFullYear()}-${numMes1}-01`;

        const texto2 = `${mesesLargo[anterior.getMonth()]} de ${anterior.getFullYear()}`;
        const mes2 = anterior.getMonth() + 1;
        const numMes2 = mes2 > 9 ? mes2 : `0${mes2}`;
        const fechaAnterior = `${anterior.getFullYear()}-${numMes2}-01`;

        setFechas({
            textoInicio: texto2,
            textoFin: texto1,
            fechaInicio: fechaAnterior,
            fechaFin: fechaActual
        });
    }, []);
    return (
        <MainCard title="Facturas" className="bg-gray">
            <Grid container className="mainContainer">
                <Grid item xs={12} md={9} className="cardContainer" padding="0px!important">
                    <Grid className="body">
                        <form onSubmit={handleSubmit(clickConsultar)}>
                            <Grid container spacing={2} padding="0px 10px">
                                <Grid
                                    item
                                    xs={12}
                                    sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center', flexDirection: 'column' }}
                                >
                                    <Typography variant="h4" alignContent="center" textAlign="center" sx={{ margin: '5px' }}>
                                        Periodo de consulta:&nbsp;{fechas.textoInicio}&nbsp;- {fechas.textoFin}
                                    </Typography>
                                </Grid>
                                <Grid item xs={12}>
                                    <TextFieldAutoComplete
                                        control={control}
                                        name="proyecto"
                                        label="Seleccione el terreno a consultar"
                                        rules={{ required: { value: true, message: 'Campo requerido' } }}
                                        options={login?.lotes}
                                        optionSelected={{ id: 'id_cobranza', label: 'vivienda' }}
                                    />
                                </Grid>
                                <Grid item xs={12} sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                                    <Button
                                        sx={{ maxWidth: '20%', margin: '10px' }}
                                        id="btn-consulta-factura"
                                        className="btn"
                                        variant="contained"
                                        color="info"
                                        type="submit"
                                    >
                                        Consultar
                                    </Button>
                                </Grid>
                            </Grid>
                        </form>
                        {clientes.length > 0 && <Grid item xs={12} backgroundColor="#f6f6f6" height="20px" margin="10px 0px" />}
                        {clientes.length > 0 ? (
                            <Grid item xs={12} padding="0px 10px">
                                <>
                                    <Typography variant="h4" alignContent="center" textAlign="center" sx={{ margin: '5px' }}>
                                        HISTORIAL
                                    </Typography>
                                    <Tabla
                                        rows={clientes}
                                        columns={columns}
                                        accionesTabla={[]}
                                        accionesFila={accionesFila}
                                        accionesFilaOrientacion="left"
                                        columnKey="foliofiscalcfdi"
                                    />
                                </>
                            </Grid>
                        ) : (
                            <></>
                        )}

                        {muestraError && (
                            <Grid
                                item
                                xs={12}
                                sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center', flexDirection: 'column' }}
                            >
                                <Typography variant="h4" alignContent="center" sx={{ margin: '5px' }}>
                                    <b>No se encontraron registros</b>
                                </Typography>
                                <Typography variant="body2" alignContent="center" textAlign="center" sx={{ margin: '5px' }}>
                                    Si usted realizó una transferencia interbancaria que no incluía su referencia, el pago no se verá
                                    reflejado en nuestros sistemas.
                                </Typography>
                                <Typography variant="body2" alignContent="center" textAlign="center" sx={{ margin: '5px' }}>
                                    La generación de facturas puede tardar hasta 10 días hábiles posteriores a la fecha de pago.
                                    <br />
                                    Para cualquier duda o aclaración contacte al área de post venta&nbsp;
                                    <a href="mailto:postventa@ciudadmaderas.com">postventa@ciudadmaderas.com</a>.
                                </Typography>
                            </Grid>
                        )}
                    </Grid>
                </Grid>
            </Grid>
        </MainCard>
    );
};

export default Facturas;
