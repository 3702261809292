import PropTypes from 'prop-types';
import { Controller } from 'react-hook-form';
import { FormControl, FormLabel, RadioGroup } from '@mui/material';

const GroupRadioButtonForm = ({ control, name, label, defaultValue, rules, children, onChange }) => (
    <Controller
        control={control}
        name={name}
        rules={rules}
        defaultValue={defaultValue || null}
        render={({ field }) => (
            <FormControl component="fieldset">
                <FormLabel component="legend">{label}</FormLabel>
                <RadioGroup
                    row
                    {...field}
                    onChange={(event) => {
                        field.onChange(event.target.value);
                        if (onChange) onChange(event.target.value);
                    }}
                >
                    {children}
                </RadioGroup>
            </FormControl>
        )}
    />
);
GroupRadioButtonForm.propTypes = {
    control: PropTypes.object.isRequired,
    name: PropTypes.string.isRequired,
    label: PropTypes.string,
    defaultValue: PropTypes.object,
    rules: PropTypes.object.isRequired,
    children: PropTypes.node,
    onChange: PropTypes.func
};

GroupRadioButtonForm.defaultProps = {
    label: '',
    defaultValue: null
};

export default GroupRadioButtonForm;
