// material-ui
import { Help } from '@mui/icons-material';
import { FormControl, FormControlLabel, FormLabel, Grid, InputAdornment, Radio, RadioGroup, TextField, Tooltip } from '@mui/material';
import { useEffect, useState } from 'react';
import { TextFieldAutoComplete } from 'ui-component';
import ServiciosAdmTer from 'utils/servicioAdmTer';
import { useLogin } from 'Context/ContextLogin';
import { useFormContext } from 'react-hook-form';
import InputLabel from '../../ui-component/inputlabel';

const FormTerreno = () => {
    const { login } = useLogin();
    const servicio = ServiciosAdmTer();
    const [listaProyectos, setListaProyectos] = useState({});
    const {
        register,
        control,
        handleSubmit,
        setValue,
        formState: { errors }
    } = useFormContext();
    useEffect(() => {
        // LLAMAMOS AL SERVICIO PARA TRAER LA LISTA DE PROYECTOS
        servicio.getProy(
            (data) => {
                // FORMATEAMOS CON FUNCION MAP EN EL FORMATO NECESARIO PARA EL TEXTFIELDAUTOCOMPLETE
                const mapRes = data.map((item) => ({
                    idproyecto: item.empresa,
                    nproyecto: item.nproyecto
                }));
                setListaProyectos(mapRes);
            },
            { idUCliente: login }
        );
        setValue('tipoLote', 'men');
    }, []);
    const radioChange = (value) => {
        setValue('tipoLote', value.target.value);
    };
    return (
        <Grid container spacing={2}>
            {/* <Grid item sx={12}>
                <InputLabel
                    // label="" // INFORMACIÓN DEL INPUT
                    // titleTooltip="hola" // PARA MOSTRAR EL MENSAJE EN TOOLTIP
                    error={errors.proyecto} // PARA APLICAR ERRORES EN LABEL Y TOOLTIP (SI APLICA)
                    input={
                        <FormControl>
                            Favor de llenar los datos solicitados.
                            <RadioGroup row name="tipoLote" onChange={radioChange} defaultValue="men">
                                <FormControlLabel value="men" control={<Radio />} label="Mensualidad" />
                                <FormControlLabel value="man" control={<Radio />} label="Mantenimiento" />
                            </RadioGroup>
                        </FormControl>
                    } // SE ENVIA EL ELEMENTO Y SE MUESTRA EN EL COMPONENTE
                />
            </Grid> */}
            <Grid item xs={12}>
                <InputLabel
                    label="Proyectos" // INFORMACIÓN DEL INPUT
                    // titleTooltip="hola" // PARA MOSTRAR EL MENSAJE EN TOOLTIP
                    error={errors.proyecto} // PARA APLICAR ERRORES EN LABEL Y TOOLTIP (SI APLICA)
                    input={
                        <TextFieldAutoComplete
                            control={control}
                            name="proyecto"
                            label="Proyecto*"
                            rules={{ required: { value: true, message: 'Campo requerido' } }}
                            options={listaProyectos}
                            optionSelected={{ id: 'idproyecto', label: 'nproyecto' }}
                        />
                    } // SE ENVIA EL ELEMENTO Y SE MUESTRA EN EL COMPONENTE
                />
            </Grid>
            <Grid item xs={12}>
                <InputLabel
                    label="Referencia" // INFORMACIÓN DEL INPUT
                    titleTooltip="Ingresa la referencia bancaria o referencia de pago de tu
                    terreno la cual podrás encontrar en tu contrato.
                    Recuerda que la referencia de pago de tu terreno es
                    diferente a la referencia de pago de tu mantenimiento." // PARA MOSTRAR EL MENSAJE EN TOOLTIP
                    error={errors.referencia} // PARA APLICAR ERRORES EN LABEL Y TOOLTIP (SI APLICA)
                    input={
                        <TextField
                            fullWidth
                            label="Referencia*"
                            error={Boolean(errors?.referencia)}
                            helperText={errors?.referencia?.message || ''}
                            size="small"
                            {...register('referencia', {
                                required: { value: true, message: 'Campo requerido' },
                                minLength: {
                                    value: 5,
                                    message: 'Minimo 5 caracteres'
                                },
                                maxLength: {
                                    value: 15,
                                    message: 'Maximo 15 caracteres'
                                }
                            })}
                            // InputProps={{
                            //     endAdornment: (
                            //         <InputAdornment>
                            //             <Tooltip
                            //                 title={
                            //                     <div>
                            //                         Ingresa la referencia bancaria o referencia de pago de tu terreno la cual podrás
                            //                         encontrar en tu contrato.
                            //                         <br />
                            //                         Recuerda que la referencia de pago de tu terreno es diferente a la referencia de pago de
                            //                         tu mantenimiento.
                            //                     </div>
                            //                 }
                            //                 disableTouchListener
                            //             >
                            //                 <Help />
                            //             </Tooltip>
                            //         </InputAdornment>
                            //     )
                            // }}
                            onKeyDown={(e) => {
                                if (e.target.value.length >= 15 && e.key != 'Backspace') {
                                    e.preventDefault();
                                }
                            }}
                        />
                    } // SE ENVIA EL ELEMENTO Y SE MUESTRA EN EL COMPONENTE
                />
            </Grid>
        </Grid>
    );
};

export default FormTerreno;
