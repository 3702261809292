// material-ui
import { Payment } from '@mui/icons-material';
import { Button, FormControl, FormControlLabel, Grid, InputLabel, MenuItem, Radio, Select, TextField } from '@mui/material';
import { useLogin } from 'Context/ContextLogin';
import { useContextFormulario } from 'Context/ContextServicios';
import axios from 'axios';
import { useRef } from 'react';
import { useForm } from 'react-hook-form';
import { register } from 'serviceWorker';
import {
    FormatNumber,
    GroupRadioButtonForm,
    TextFieldAutoComplete,
    TextFieldFormato,
    TextFieldNumber,
    TextFieldSelectImage
} from 'ui-component';

import SubCard from 'ui-component/cards/SubCard';
import FormDatosBBVA from './FormDatosBBVA';

const FormPago = ({ infoLote, fechaActual, actualizarTerreno, esVencidos }) => {
    const formPago = useForm();
    const { abrirFormulario } = useContextFormulario();
    const refFormularioBanco = useRef(null);
    const banbajio = (compra) => {
        const horaActual = new Date().toLocaleTimeString('it-IT');
        refFormularioBanco.current.children.cl_concepto.value = '2';
        refFormularioBanco.current.children.cl_folio.value = `${fechaActual.replaceAll('-', '')}${horaActual.replaceAll(':', '')}`;
        refFormularioBanco.current.children.cl_referencia.value = `MENBJ-${infoLote.proyecto}-${infoLote.referencia}-${infoLote.id_cobranza}`;
        refFormularioBanco.current.children.cl_servicio.value = '139';
        refFormularioBanco.current.children.dl_monto.value = compra.monto;
        refFormularioBanco.current.children.abocap = '0';
        const popup = window.open(
            '',
            'formulario_mensualidad',
            'width=900,height=590,toolbar=no,scrollbars=yes,resizable=no,location=0,directories=0,status=1,menubar=0,left=60,top=60'
        );
        const timer = setInterval(() => {
            if (popup.closed) {
                clearInterval(timer);
                actualizarTerreno({ idCobranza: infoLote.idCobranza });
            }
        }, 1000);

        refFormularioBanco.current.target = 'formulario_mensualidad';
        refFormularioBanco.current.submit();
    };

    const enviarPostJSON = (callback, d, url, b64 = false) => {
        const object = {};
        let jsonfd = '{}';
        if (d instanceof FormData) {
            d.forEach((value, key) => {
                object[key] = value;
            });
            jsonfd = JSON.stringify(object);
        } else jsonfd = JSON.stringify(d);

        axios({
            url: url,
            data: b64 ? btoa(jsonfd) : jsonfd,
            method: 'POST'
        })
            .then((resp) => {
                callback(JSON.parse(b64 ? atob(resp.data) : resp.data));
            })
            .catch((thrown) => {
                if (axios.isCancel(thrown)) {
                    console.log('Peticion Cancelada', thrown.message);
                } else {
                    console.log('Peticion Cancelada', thrown);
                }
            });
    };
    const abrirBBVA = (compra) => {
        const clReferencia = `MENBBVA-${infoLote.proyecto}-${infoLote.referencia}-${infoLote.id_cobranza}`;
        const jsBBVA = {
            monto: parseFloat(String(compra.monto).replace(',', '')),
            concepto: `${clReferencia}-${fechaActual.replaceAll('-', '')}${String(Math.round(Math.random() * (999 - 1)) + 1).padStart(
                3,
                '0'
            )}`,
            afiliacion: infoLote.afil_bbva,
            ncliente: infoLote.nomCliente,
            apcliente: ' ',
            mailcliente: compra.correo,
            telcliente: compra.telefono
        };
        enviarPostJSON(
            (response) => {
                if (response.resultado) {
                    window.location.replace(response.url);
                } else {
                    console.log('Ha ocurrido un error al procesar la información');
                }
            },
            jsBBVA,
            'https://pagosciudadmaderas.com/BBVA/index.php',
            true
        );
    };
    const abrirBanco = (compra) => {
        if (compra.banco == 0) banbajio(compra);
        else
            abrirFormulario('Datos de contacto', FormDatosBBVA, {}, (datosContacto) => abrirBBVA({ ...compra, ...datosContacto }), {
                mensajeConfirmacion: false
            });
        // abrirBBVA(compra);
    };
    return (
        <SubCard title="Cantidad a pagar*">
            <form onSubmit={formPago.handleSubmit(abrirBanco)}>
                <Grid container spacing={2} alignContent="center" direction="column">
                    <Grid item container justifyContent="center">
                        {!esVencidos && (
                            <Grid item xs={3} md={2}>
                                <TextField
                                    {...formPago.register('minCapital', { required: { value: true, message: 'Campo requerido' } })}
                                    label="¿Pago a capital?"
                                    fullWidth
                                    size="small"
                                    select
                                    SelectProps={{ native: true }}
                                >
                                    <option value="0">No</option>
                                    <option value="1" disabled={!infoLote.minCapital}>
                                        Si
                                    </option>
                                </TextField>
                            </Grid>
                        )}
                        <Grid item xs md={3}>
                            <TextFieldNumber
                                name="monto"
                                label="Monto"
                                rules={{
                                    required: { value: true, message: 'Campo requerido' },
                                    validate: (monto) => {
                                        const error = monto > 0 ? false : 'Monto insuficiente';
                                        if (error) return error;
                                        return (
                                            formPago.getValues('minCapital') == 0 ||
                                            monto >= infoLote.minCapital || (
                                                <>
                                                    Monto minimo a capital debe ser mayor a&nbsp;
                                                    <FormatNumber type="money" value={infoLote.minCapital} />
                                                </>
                                            )
                                        );
                                    }
                                }}
                                error={formPago.formState.errors.monto?.message}
                                control={formPago.control}
                                prefix="$"
                            />
                        </Grid>

                        <Grid item xs md={3}>
                            <TextField
                                {...formPago.register('banco', { required: { value: true, message: 'Campo requerido' } })}
                                label="Banco"
                                error={formPago.formState.errors.banco}
                                helperText={formPago.formState.errors.banco?.message}
                                control={formPago.control}
                                size="small"
                                select
                                fullWidth
                            >
                                <MenuItem value="0">
                                    <img src="https://pagosciudadmaderas.com/pago/images/banbajio.png" alt="" value="en" height="15rem" />
                                </MenuItem>
                                <MenuItem value="1">
                                    <img src="https://pagosciudadmaderas.com/pago/images/BBVA-logo.png" alt="" value="en" height="15rem" />
                                </MenuItem>
                            </TextField>
                        </Grid>
                    </Grid>
                    <Grid item container justifyContent="center">
                        <Grid item xs={8} md={2}>
                            <Button
                                variant="contained"
                                size="medium"
                                disabled={!infoLote?.id_cobranza}
                                fullWidth
                                color="primary"
                                type="submit"
                                startIcon={<Payment />}
                            >
                                Pagar
                            </Button>
                        </Grid>
                    </Grid>
                </Grid>
            </form>
            <form
                id="formulario_mensualidad"
                method="post"
                ref={refFormularioBanco}
                action="https://pagosciudadmaderas.com/BB/MigracionBBB.php"
                target="Popup_Window"
                onSubmit={(event) => {
                    let popup;
                    if (popup && !popup.close()) {
                        popup.close();
                    }
                    popup = window.open('about:blank', 'Popup_Window', 'width=870, height=600, menubar=no,scrollbars=yes, directories=no');
                    event.target = 'Popup_Window';
                    setInterval(() => {
                        if (popup.closed) {
                            window.location.reload(true);
                        }
                    }, 1000);
                }}
            >
                <input id="cl_concepto" name="cl_concepto" type="hidden" value="1" />
                <input id="cl_folio" name="cl_folio" type="hidden" value="5050125" />
                <input id="cl_referencia" name="cl_referencia" type="hidden" value="DEfault" />
                <input id="cl_servicio" name="cl_servicio" type="hidden" value="413" />
                <input type="hidden" id="dl_monto" name="dl_monto" value={2} />
            </form>
        </SubCard>
    );
};
export default FormPago;
