import React from 'react';

import PropTypes from 'prop-types';
import NumberFormat from 'react-number-format';

const FormatNumber = (props) => {
    const { value, type } = props;
    let tiposeleccionado = null;

    switch (type) {
        case 'money':
            tiposeleccionado = (
                <NumberFormat value={value} displayType="text" thousandSeparator prefix="$" decimalScale={2} fixedDecimalScale />
            );
            break;
        default:
            tiposeleccionado = value;
    }
    return tiposeleccionado;
};

FormatNumber.propTypes = {
    value: PropTypes.oneOfType([PropTypes.number, PropTypes.string]).isRequired
};
export default FormatNumber;
