import { borderColor, borderRadius, height } from '@mui/system';

export default function componentStyleOverrides(theme) {
    const bgColor = theme.colors?.grey50;
    return {
        // CLASES PARA EL HEADER
        MuiToolBar: {
            styleOverrides: {
                root: {
                    backgroundColor: 'red',
                    fontFamily: 'Raleway'
                }
            }
        },
        // CLASES PARA EL FOOTER
        MuiStack: {
            styleOverrides: {
                root: {
                    '&.footer2': {
                        height: '83px',
                        width: '100%',
                        position: 'fixed',
                        bottom: '0px',
                        zIndex: '100',
                        padding: '10px',
                        backgroundColor: '#F3F4F6'
                    }
                }
            }
        },
        MuiButton: {
            styleOverrides: {
                root: {
                    borderRadius: '10px',
                    '&.btn-info': {
                        backgroundColor: '#00A4FF',
                        color: '#FFFFFF',
                        borderRadius: '15px'
                    },
                    '&.btn': {
                        height: '25px',
                        fontSize: '13pt',
                        fontFamily: 'Calibri Regular',
                        width: '100%',
                        textTransform: 'initial'
                    },
                    '&.login': {
                        color: '#FFFFFF'
                    },
                    '&.register': {
                        color: '#023965',
                        backgroundColor: '#d2eeff'
                    }
                }
            }
        },
        // CLASES PARA EL BOTON EN EL MENÚ DE INICIO
        MuiButtonBase: {
            styleOverrides: {
                root: {
                    justifyContent: 'space-evenly!important',
                    fontFamily: 'Raleway',
                    // ESTILO DE BOTON DE INICIO
                    '&.btn-custom': {
                        position: 'relative',
                        height: '150px',
                        width: '150px',
                        direction: 'flex',
                        flexDirection: 'column',
                        justifyContent: 'space-between',
                        borderRadius: '50%',
                        padding: '10px',
                        '&:hover': {
                            color: '#B4A269'
                        }
                    },
                    // ICONO DE BOTON DE INICIO
                    img: {
                        '&.btn-image': {
                            height: '60px',
                            width: '60px',
                            color: '#B4A269'
                        }
                    },
                    // TEXTO EN BOTON DE INICIO
                    span: {
                        '&.btn-label': {
                            fontFamily: 'Raleway',
                            fontSize: '21px',
                            color: '#0E2439',
                            '&:hover': {
                                color: '#B4A269'
                            }
                        }
                    }
                }
            },
            defaultProps: {
                // disableRipple: true
            }
        },
        MuiPaper: {
            defaultProps: {
                elevation: 0
            },
            styleOverrides: {
                root: {
                    backgroundImage: 'none',
                    '&.bg-gray': {
                        backgroundColor: '#F6F6F6',
                        overflow: 'scroll',
                        borderRadius: '0px',
                        overflowX: 'hidden'
                    }
                },
                rounded: {
                    borderRadius: `${theme?.customization?.borderRadius}px`
                    // borderRadius: '70px'
                }
            }
        },
        MuiCard: {
            styleOverrides: {
                root: {
                    // LOS CARD ABARCAN EL 100% DEL TAMAÑO DEL CONTENEDOR
                    height: '100%'
                }
            }
        },
        MuiCardHeader: {
            styleOverrides: {
                root: {
                    color: theme.colors?.textDark,
                    padding: '24px'
                },
                title: {
                    fontSize: '4vw',
                    color: '#0E2439',
                    textAlign: 'center',
                    fontFamily: 'Raleway'
                }
            }
        },
        MuiCardContent: {
            styleOverrides: {
                root: {
                    padding: '24px',
                    height: '100%'
                }
            }
        },
        MuiCardActions: {
            styleOverrides: {
                root: {
                    padding: '24px'
                }
            }
        },
        // SOBRE-ESCRIBIMOS LAS PROPIEDADES DEL ELEMENTO
        MuiGrid: {
            styleOverrides: {
                root: {
                    // ESCRIBIMOS LA CLASE Y LAS PROPIEDADES
                    '&.mainContainer': {
                        fontFamily: 'Raleway',
                        height: '100%',
                        display: 'flex',
                        flexDirection: 'column',
                        justifyContent: 'flex-start',
                        backgroundColor: '#F6F6F6',
                        alignItems: 'center'
                    },
                    '&.cardContainer': {
                        backgroundColor: 'white',
                        borderTop: '5px outset #2B5687',
                        width: '100%',
                        padding: '0px 10px'
                    },
                    // ESTILOS PARA EL CONTENIDO DE LOS MODULOS
                    '&.header': {
                        textAlign: 'center',
                        fontFamily: '"Raleway", sans-serif',
                        fontOpticalSizing: 'auto',
                        fontWeight: '60',
                        fontStyle: 'normal',
                        color: '#0E2439',
                        h2: {
                            fontFamily: 'Raleway',
                            fontSize: '3vw'
                        },
                        p: {
                            fontFamily: 'Raleway',
                            fontSize: '20pt'
                        }
                    },
                    '&.body': {
                        width: '100%',
                        height: '100%',
                        marginTop: '10px'
                    },
                    // CARD DE INICIO
                    '&.card': {
                        height: '350px',
                        backgroundColor: '#FFFFFF',
                        borderRadius: '30px 30px 0px 0px',
                        zIndex: '10',
                        boxShadow: '0px 0px 5px 0px #292929',
                        position: 'relative',
                        bottom: '-1px'
                    },
                    '&.item': {
                        display: 'flex',
                        justifyContent: 'center'
                    }
                }
            }
        },
        MuiListItemButton: {
            styleOverrides: {
                root: {
                    color: theme.darkTextPrimary,
                    paddingTop: '10px',
                    paddingBottom: '10px',
                    '&:hover': {
                        backgroundColor: theme.menuSelectedBack,
                        color: theme.menuSelected,
                        '& .MuiListItemIcon-root': {
                            color: theme.menuSelected
                        }
                    }
                },
                MuiSelected: {
                    color: theme.menuSelected,
                    backgroundColor: theme.menuSelectedBack
                }
            }
        },
        MuiListItemIcon: {
            styleOverrides: {
                root: {
                    color: theme.darkTextPrimary,
                    minWidth: '36px'
                }
            }
        },
        MuiListItemText: {
            styleOverrides: {
                root: {
                    // PARA EL TEXTO EN EL MENÚ LATERAL
                    span: {
                        fontFamily: 'Raleway',
                        fontSize: '10pt',
                        color: '#0E2439'
                    }
                },
                primary: {
                    color: theme.textDark
                }
            }
        },
        MuiInputBase: {
            styleOverrides: {
                input: {
                    color: theme.textDark,
                    '&::placeholder': {
                        color: theme.darkTextSecondary,
                        fontSize: '0.875rem'
                    }
                }
            }
        },
        // ESTILOS PARA LOS TEXTAUTOCOMPLETE
        MuiOutlinedInput: {
            styleOverrides: {
                root: {
                    background: bgColor,
                    // borderRadius: `${theme?.customization?.borderRadius}px`,
                    borderRadius: '5px',
                    '& .MuiOutlinedInput-notchedOutline': {
                        // borderColor: theme.colors?.grey400,
                        borderColor: '#606060',
                        borderWidth: '.25pt',
                        borderRadius: '5px'
                    },
                    // CON ESTO MODIFICAMOS EL COLOR DEL BORDE EN EL TEXTAUTOCOMPLETE
                    '&:hover .MuiOutlinedInput-notchedOutline': {
                        borderColor: '#00A0FF',
                        borderWidth: '2pt'
                    },
                    // '&:hover $notchedOutline': {
                    //     borderColor: 'red'
                    // },
                    // '&:hover': {
                    //     borderColor: 'red'
                    // },
                    '&.MuiInputBase-multiline': {
                        padding: 1
                    }
                },
                input: {
                    fontWeight: 500,
                    background: bgColor,
                    padding: '15.5px 14px',
                    // borderRadius: `${theme?.customization?.borderRadius}px`,
                    borderRadius: '100px',
                    '&.MuiInputBase-inputSizeSmall': {
                        padding: '10px 14px',
                        '&.MuiInputBase-inputAdornedStart': {
                            paddingLeft: 0
                        }
                    }
                },
                inputAdornedStart: {
                    paddingLeft: 4
                },
                notchedOutline: {
                    // borderRadius: `${theme?.customization?.borderRadius}px`,
                    borderRadius: '100px'
                }
            }
        },
        MuiSlider: {
            styleOverrides: {
                root: {
                    '&.Mui-disabled': {
                        color: theme.colors?.grey300
                    }
                },
                mark: {
                    backgroundColor: theme.paper,
                    width: '4px'
                },
                valueLabel: {
                    color: theme?.colors?.primaryLight
                }
            }
        },
        MuiDivider: {
            styleOverrides: {
                root: {
                    borderColor: theme.divider,
                    opacity: 1
                }
            }
        },
        MuiAvatar: {
            styleOverrides: {
                root: {
                    color: theme.colors?.primaryDark,
                    background: theme.colors?.primary200
                }
            }
        },
        MuiAlert: {
            styleOverrides: {
                root: {
                    color: 'white'
                }
            }
        },
        MuiChip: {
            styleOverrides: {
                root: {
                    '&.MuiChip-deletable .MuiChip-deleteIcon': {
                        color: 'inherit'
                    }
                }
            }
        },
        MuiTooltip: {
            styleOverrides: {
                tooltip: {
                    color: theme.paper,
                    background: theme.colors?.grey700
                }
            }
        },
        img: {
            styleOverrides: {
                root: {
                    '&.itemCarousel': {
                        backgroundColor: 'red'
                    }
                }
            }
        }
    };
}
