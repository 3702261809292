import { Typography, Grid } from '@mui/material';
import React, { useState, useEffect } from 'react';
import MainCard from 'ui-component/cards/MainCard';
import { makeStyles } from '@mui/styles';

const useStyles = makeStyles((theme) => ({
    itemTexto: {
        margin: '30px'
    },
    contenedor: {
        backgroundColor: 'white'
    }
}));

const PreguntasFrecuentes = (props) => {
    const variable = 0;
    const classes = useStyles();
    return (
        <MainCard title="Preguntas frecuentes" className="bg-gray">
            <Grid container className="mainContainer" spacing={4}>
                <Grid item xs={12} md={9} className="cardContainer" padding="0px!important">
                    <div className={classes.itemTexto}>
                        <Typography variant="h4">¿Cuál es el horario de servicio al cliente presencial y vía telefónica?</Typography>
                        <Typography variant="body">
                            El horario de Servicio al Cliente es de lunes a viernes de 08:00 a 18:00 hrs, y sábados de 09:00 a 14:00 hrs.
                        </Typography>
                    </div>
                    <div className={classes.itemTexto}>
                        <Typography variant="h4">¿Dónde puedo realizar mi pago de terreno, casa y/o mantenimiento?</Typography>
                        <Typography variant="body">
                            Tenemos varias opciones de pago:
                            <br />
                            <br />
                            Página web www.ciudadmaderas.com, es necesario tener a la mano su referencia de pago, la encuentra en su
                            contrato.
                            <br />
                            Realizar transferencia desde su portal bancario, indispensable agregar número de referencia.
                            <br />
                            Depósito bancario, número de referencia.
                            <br />
                            Depósito en cajas de oficinas de Ciudad Maderas.
                        </Typography>
                    </div>
                    <div className={classes.itemTexto}>
                        <Typography variant="h4">¿Dónde obtengo mi referencia de terreno y/o mantenimiento?</Typography>
                        <Typography variant="body">
                            El número de referencia de pago, número de cuenta clave y cuenta del terreno los encuentra en su contrato o los
                            puede solicitar al correo <a href="mailto:postventa@ciudadmaderas.com">postventa@ciudadmaderas.com</a> indicando
                            desarrollo, condominio, número de terreno y nombre completo del titular.
                            <br />
                            Para pago de mantenimiento, favor de solicitar la referencia al correo&nbsp;
                            <a href="mailto:condominios@gph.mx">condominios@gph.mx</a>&nbsp;indicando desarrollo, condominio, número de
                            terreno y nombre completo del titular.
                        </Typography>
                    </div>
                    <div className={classes.itemTexto}>
                        <Typography variant="h4">
                            ¿Puedo descargar mi factura del terreno, casa y/o mantenimiento de la página web?
                        </Typography>
                        <Typography variant="body">
                            Sí, ingrese a la página de <a href="www.ciudadmaderas.com">www.ciudadmaderas.com</a> en el apartado de atención
                            a clientes, genere una cuenta y registre su(s) terreno(s), una vez registrado podrá consultar y descargar sus
                            facturas. Es de suma importancia realizar su pago con referencia bancaria, de lo contrario las facturas no
                            aparecen en su cuenta y serán aplicadas hasta que los envíe al correo de&nbsp;
                            <a href="mailto.postventa@ciudadmaderas.com">postventa@ciudadmaderas.com</a>&nbsp;si es terreno, o al correo de
                            <a href="mailto:condominios@gph.mx">condominios@gph.mx</a> si es mantenimiento, indicando desarrollo,
                            condominio, número de terreno y nombre completo del titular.
                        </Typography>
                    </div>
                    <div className={classes.itemTexto}>
                        <Typography variant="h4">¿Dónde solicito un estado de cuenta del terreno, casa y/o mantenimiento?</Typography>
                        <Typography variant="body">
                            Puede descargar su estado de cuenta directamente en la página de&nbsp;
                            <a href="www.ciudadmaderas.com">www.ciudadmaderas.com</a>&nbsp;en el apartado de atención a clientes, genere una
                            cuenta y registre su(s) terreno(s), una vez registrado podrá consultar y descargarlo directamente. Para
                            cualquier duda referente a su estado de cuenta favor de enviar un correo a la dirección&nbsp;
                            <a href="mailto:postventa@ciudadmaderas.com">postventa@ciudadmaderas.com</a>&nbsp;si es terreno,o al correo de
                            &nbsp;
                            <a href="mailto:condominios@gph.mx">condominios@gph.mx</a>&nbsp;si es mantenimiento, indicando desarrollo,
                            condominio, número de terreno y nombre completo del titular.
                        </Typography>
                    </div>
                    <div className={classes.itemTexto}>
                        <Typography variant="h4">
                            ¿Qué hago si realicé una transferencia bancaria para el pago de mi terreno, casa y/o mantenimiento y no puse mi
                            número de referencia?
                        </Typography>
                        <Typography variant="body">
                            Es necesario que envíe su comprobante de pago al correo&nbsp;
                            <a href="mailto:postventa@ciudadmaderas.com">postventa@ciudadmaderas.com</a>&nbsp;si es terreno y al correo
                            de&nbsp; <a href="mailto:condominios@gph.mx">condominios@gph.mx</a>&nbsp;si es mantenimiento, indicando nombre
                            del desarrollo, condominio, número de terreno y nombre completo del titular para que se identifique el pago y
                            realice la aplicación.
                        </Typography>
                    </div>
                    <div className={classes.itemTexto}>
                        <Typography variant="h4">¿Cómo realizo un abono a capital?</Typography>
                        <Typography variant="body">
                            Los abonos a capital se realizan únicamente en las cajas de las oficinas de Ciudad Maderas.
                        </Typography>
                    </div>
                    <div className={classes.itemTexto}>
                        <Typography variant="h4">¿Cuál es el número telefónico de atención al cliente?</Typography>
                        <Typography variant="body">
                            <a href="tel:018002120013">01 800 212 0013</a>
                        </Typography>
                    </div>
                    <div className={classes.itemTexto}>
                        <Typography variant="h4">¿En qué estados del país hay oficinas de atención y ventas de Ciudad Maderas?</Typography>
                        <Typography variant="body">
                            Tenemos desarrollos habitacionales los estados de Querétaro, Guanajuato (LEÓN), San Luis Potosí, Yucatán
                            (MÉRIDA). Además de estas Ciudades, también contamos con una oficina de ventas y atención a clientes en la CDMX.
                        </Typography>
                    </div>
                    <div className={classes.itemTexto}>
                        <Typography variant="h4">¿Debo realizar algún mantenimiento extra en mi terreno?</Typography>
                        <Typography variant="body">
                            Cada propietario es responsable de la limpieza de su terreno, es necesario llevarlo a cabo 2 veces al año en los
                            meses de enero y posterior a la temporada de lluvias para evitar la proliferación de plagas y animales. Previo
                            al ingreso deberá notificar a su administrador de condominio por escrito al correo&nbsp;
                            <a href="mailto:condominios@gph.mx">condominios@gph.mx</a>&nbsp;, la fecha y hora así como el nombre completo de
                            las personas que realizaran la limpieza, deberán presentar una identificación oficial vigente para su registro
                            en la caseta de acceso. Los horarios permitidos son de lunes a viernes de 8:00 a.m. a 6:00 p.m. y sábados de
                            8:00 a.m. a 2:00 p.m.
                        </Typography>
                    </div>
                    <div className={classes.itemTexto}>
                        <Typography variant="h4">¿A partir de cuándo debo pagar mantenimiento de mi condominio?</Typography>
                        <Typography variant="body">
                            La administración del condominio inicia cuando las instalaciones empiezan a operar. Para esto, se debe
                            constituir el fondo de reserva y realizar los pagos mensuales por concepto de cuota de mantenimiento. Consulte
                            el inicio de administración de su condominio en el correo&nbsp;
                            <a href="mailto:condominios@gph.mx">condominios@gph.mx</a>&nbsp;.
                        </Typography>
                    </div>
                    <div className={classes.itemTexto}>
                        <Typography variant="h4">¿Qué servicios cubre mi cuota de mantenimiento?</Typography>
                        <Typography variant="body">
                            La cuota de mantenimiento incluye los siguientes servicios:
                            <br />
                            <br />
                            Jardinería áreas comunes.
                            <br />
                            Limpieza áreas comunes.
                            <br />
                            Control de acceso 24x24.
                            <br />
                            Recolección de Basura.
                            <br />
                            Agua áreas comunes (caseta de vigilancia, alberca, baños casa club y riego de áreas verdes).
                            <br />
                            Luz de áreas comunes (caseta de vigilancia, alberca, casa club, alumbrado público y riego de áreas verdes).
                            <br />
                            Mantenimiento alberca.
                            <br />
                            Administración, cobranza, contador, planeación fiscal y jurídica.
                            <br />
                            Indirectos de Oficina.
                        </Typography>
                    </div>
                    <div className={classes.itemTexto}>
                        <Typography variant="h4">¿Cómo contacto a mi administrador de condominio?</Typography>
                        <Typography variant="body">
                            Puede enviar un correo electrónico a la dirección &nbsp;
                            <a href="mailto:condominios@gph.mx">condominios@gph.mx</a>
                            &nbsp;indicando nombre del desarrollo, condominio, número de terreno y nombre completo del titular para que su
                            administrador se ponga en contacto con usted.
                        </Typography>
                    </div>
                    <div className={classes.itemTexto}>
                        <Typography variant="h4">¿Por qué debo pagar mantenimiento?</Typography>
                        <Typography variant="body">
                            Al realizar el pago de sus cuotas de mantenimiento usted permite que los servicios del condominio se lleven a
                            cabo, además de que el condominio tenga un perfecto funcionamiento. Recuerde que la vida y belleza de las áreas
                            verdes, la limpieza de las calles y alberca, el funcionamiento del gimnasio y casa club, dependen del pago que
                            usted realiza mes con mes. Además, el pago puntual de su mantenimiento garantiza la plusvalía del valor de su
                            terreno en el tiempo, para esto es indispensable que el desarrollo se mantenga impecable.
                        </Typography>
                    </div>
                </Grid>
            </Grid>
        </MainCard>
    );
};

export default PreguntasFrecuentes;
