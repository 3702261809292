import { TextField } from '@mui/material';
import React from 'react';
import { Controller } from 'react-hook-form';
import PropTypes from 'prop-types';
import NumberFormat from 'react-number-format';

const TextFieldNumber = (props) => {
    const { name, error, label, rules, defaultValue, control, prefix, suffix, onChange, disabled } = props;
    return (
        <Controller
            defaultValue={defaultValue}
            rules={rules}
            name={name}
            control={control}
            render={({ field }) => (
                <NumberFormat
                    customInput={TextField}
                    thousandSeparator
                    onValueChange={(v) => {
                        field.onChange(v.value);
                        if (onChange) onChange(v.value);
                    }}
                    suffix={suffix}
                    prefix={prefix}
                    allowNegative={false}
                    disabled={disabled}
                    inputRef={field.ref}
                    defaultValue={defaultValue}
                    label={label}
                    size="small"
                    fullWidth
                    error={Boolean(error)}
                    helperText={error}
                />
            )}
        />
    );
};
TextFieldNumber.propTypes = {
    name: PropTypes.string.isRequired,
    rules: PropTypes.object,
    error: PropTypes.any,
    label: PropTypes.string.isRequired,
    defaultValue: PropTypes.any,
    control: PropTypes.any,
    suffix: PropTypes.string,
    prefix: PropTypes.string,
    onChange: PropTypes.func,
    disabled: PropTypes.bool
};
export default TextFieldNumber;
