import PropTypes from 'prop-types';
import { makeStyles } from '@mui/styles';
import { Grid, Tooltip, Typography } from '@mui/material';
import { useEffect } from 'react';

// ESTILOS PROPIOS PARA EL ELEMENTO
const useStyles = makeStyles((theme) => ({
    // CONTENEDOR
    container: {
        height: '100%',
        width: '100%',
        display: 'flex',
        alignContent: 'center',
        justifyContent: 'center'
    },
    // ESTILOS PARA EL LABEL Y TOOLTIP
    fieldLabel: {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center'
    },
    tooltip: {
        height: '35px',
        width: '20px',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        color: '#ffffff!important',
        backgroundColor: '#979797',
        borderRadius: '8px 0px 0px 8px',
        fontFamily: 'emoji',
        fontSize: '20pt'
    },
    label: {
        marginLeft: '5px',
        fontFamily: '"Raleway Medium"',
        fontSize: '28pt',
        color: '#0E2439',
        backgroundColor: 'inherit!important'
    },
    // ESTILO PARA LOS ERRORES
    error: {
        color: 'red',
        backgroundColor: 'red'
    }
}));

const InputLabel = (props) => {
    const classes = useStyles();
    const { label, titleTooltip, input, error, style } = props;
    return (
        <Grid container className={classes.container} style={style}>
            <Grid item className={classes.fieldLabel} xs={12}>
                {titleTooltip && label ? (
                    // USAMOS ERROR PARA CAMBIAR LA CLASE DEL COMPONENTE, SI EXISTE ERROR ENTONCES LO MARCA
                    <Tooltip className={[classes.tooltip, error ? classes.error : '']} placement="left" title={titleTooltip}>
                        i
                    </Tooltip>
                ) : (
                    ''
                )}
                {label ? <Typography className={[classes.label, error ? classes.error : '']}>{label}</Typography> : ''}
            </Grid>

            <Grid item xs={12}>
                {/* MOSTRAMOS EL COMPONENTE QUE FUE ENVIADO POR PROPS, DE ESTA FORMA NO LIMITAMOS A UN SOLO TIPO DE COMPONENTE */}
                {input}
            </Grid>
        </Grid>
    );
};

export default InputLabel;
