import React from 'react';
import PropTypes from 'prop-types';
import { Controller } from 'react-hook-form';
import Autocomplete from '@mui/material/Autocomplete';
import { TextField } from '@mui/material';

const AutocompleteForm = ({
    control,
    name,
    label,
    defaultValue,
    disabled,
    onChange,
    hidden,
    rules,
    error,
    options,
    optionSelected,
    value
}) => {
    if (control)
        return (
            <Controller
                control={control}
                name={name}
                rules={rules}
                defaultValue={defaultValue || null}
                render={({ field, fieldState }) => (
                    <Autocomplete
                        onChange={(event, item) => {
                            field.onChange(item);
                            if (onChange) onChange(item);
                        }}
                        size="small"
                        fullWidth
                        noOptionsText="No hay coincidencias"
                        disabled={disabled}
                        options={options || []}
                        value={field.value}
                        defaultValue={defaultValue || null}
                        getOptionLabel={
                            typeof optionSelected.label === 'function' ? optionSelected.label : (item) => item[optionSelected.label]
                        }
                        multiple={Array.isArray(defaultValue)}
                        isOptionEqualToValue={(option, value) =>
                            value == undefined || value === '' || option[optionSelected.id] === value[optionSelected.id]
                        }
                        renderInput={(params) => (
                            <TextField
                                {...params}
                                variant="outlined"
                                hidden={hidden}
                                label={label}
                                error={Boolean(fieldState.error)}
                                helperText={fieldState.error?.message}
                            />
                        )}
                    />
                )}
            />
        );
    return (
        <Autocomplete
            onChange={(event, item) => {
                if (onChange) onChange(item);
            }}
            value={value}
            size="small"
            fullWidth
            noOptionsText="No hay coincidencias"
            disabled={disabled}
            options={options || []}
            defaultValue={defaultValue || null}
            getOptionLabel={typeof optionSelected.label === 'function' ? optionSelected.label : (item) => item[optionSelected.label]}
            multiple={Array.isArray(defaultValue)}
            isOptionEqualToValue={(option, value) =>
                value == undefined || value === '' || option[optionSelected.id] === value[optionSelected.id]
            }
            renderInput={(params) => (
                <TextField {...params} variant="outlined" hidden={hidden} label={label} error={Boolean(error)} helperText={error} />
            )}
        />
    );
};

AutocompleteForm.propTypes = {
    control: PropTypes.object,
    name: PropTypes.string.isRequired,
    label: PropTypes.string,
    disabled: PropTypes.bool,
    defaultValue: PropTypes.object,
    rules: PropTypes.object,
    error: PropTypes.string,
    options: PropTypes.arrayOf(PropTypes.object).isRequired,
    optionSelected: PropTypes.shape({
        label: PropTypes.oneOfType([PropTypes.string, PropTypes.func]).isRequired,
        id: PropTypes.oneOfType([PropTypes.string, PropTypes.func]).isRequired
    })
};

AutocompleteForm.defaultProps = {
    label: '',
    defaultValue: null,
    optionSelected: { id: 'default', label: 'default' }
};

export default AutocompleteForm;
