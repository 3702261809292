import { lazy } from 'react';
import { Navigate } from 'react-router';

// project imports
import MainLayout from 'layout/MainLayout';
import PerfilUsuario from 'views/PerfilUsuario';
import Inicio from 'views/Inicio';
import Facturas from 'views/Facturas';
import EstadoCuenta from 'views/EstadoCuenta';
import PagosLinea from 'views/PagosLinea';
import AdministrarTerreno from 'views/AdministrarTerreno';
import PreguntasFrecuentes from 'views/PreguntasFrecuentes';

// import useServicioLogin from 'utils/ServiciosLogin';
// import { useLogin } from 'Context/ContextLogin';

// ==============================|| MAIN ROUTING ||============================== //

// const handleLogout = async () => {
//     const serviciosLogin = useServicioLogin();
//     const { login, cerrarLogin } = useLogin();

//     // cerrarSesion(() => cerrarLogin());
//     serviciosLogin.cerrarSesion(
//         (data) => {
//             cerrarLogin();
//         },
//         { token: login.token }
//     );
// };

const rutas = [
    {
        // id: '1',
        // idPadre: '1',
        // ncategoria: 'Ayuda',
        npagina: 'Preguntas frecuentes',
        // rutaPadre: '/Ayuda',
        idpagina: 'preFre',
        ruta: '/PreguntasFrecuentes',
        raiz: '0'
    },
    { npagina: 'Términos y condiciones', idpagina: 'termCon', ruta: '#', raiz: '0' },
    { npagina: 'Cerrar sesión', idpagina: 'logOut', ruta: '#', raiz: '0', funcion: 'handleLogout' },
    { npagina: 'Administrar terreno', ruta: '/AdministrarTerreno', raiz: '0' },
    { npagina: 'Estado de cuenta', ruta: '/EstadoCuenta', raiz: '0' },
    { npagina: 'Facturas', ruta: '/Facturas', raiz: '0' },
    { npagina: 'Pago en línea', ruta: '/PagosEnlinea', raiz: '0' }

    // { id: '1', npagina: 'Preguntas', rutaPadre: '/Ayuda', ruta: '/PagosEnlinea', raiz: '0' }
];

const MainRoutes = (usuario) => ({
    path: '/',
    element: <MainLayout rutas={rutas} usuario={usuario} />,
    children: [
        {
            path: '/perfil',
            element: <PerfilUsuario usuario={usuario} permisos={{ cambiarContrasena: !usuario?.idcontrato }} />
        },
        {
            path: '/',
            element: <Inicio usuario={usuario} permisos={{ cambiarContrasena: !usuario?.idcontrato }} />
        },
        {
            path: '/Facturas',
            element: <Facturas usuario={usuario} permisos={{ cambiarContrasena: !usuario?.idcontrato }} />
        },
        {
            path: '/EstadoCuenta',
            element: <EstadoCuenta usuario={usuario} permisos={{ cambiarContrasena: !usuario?.idcontrato }} />
        },
        {
            path: '/PagosEnlinea',
            element: <PagosLinea usuario={usuario} permisos={{ cambiarContrasena: !usuario?.idcontrato }} />
        },
        {
            path: '/AdministrarTerreno',
            element: <AdministrarTerreno usuario={usuario} permisos={{ cambiarContrasena: !usuario?.idcontrato }} />
        },
        {
            // path: '/Ayuda/PreguntasFrecuentes',
            path: '/PreguntasFrecuentes',
            element: <PreguntasFrecuentes usuario={usuario} permisos={{ cambiarContrasena: !usuario?.idcontrato }} />
        },
        { path: '*', element: <Navigate to="/" /> }
    ]
});

export default MainRoutes;
