import React from 'react';
import { makeStyles } from '@mui/styles';
import {
    Typography,
    colors,
    Dialog,
    DialogActions,
    DialogTitle,
    DialogContent,
    DialogContentText,
    Divider,
    Slide,
    Button
} from '@mui/material';

const useStyles = makeStyles((theme) => ({
    cancelar: {
        color: theme.palette.btn.red,
        '&:hover': {
            backgroundColor: theme.palette.btn.red,
            color: theme.palette.btn.white
        }
    },
    aceptar: {
        color: theme.palette.btn.green,
        '&:hover': {
            backgroundColor: theme.palette.btn.green,
            color: theme.palette.btn.white
        }
    }
}));

const Transition = React.forwardRef((props, ref) => <Slide direction="up" ref={ref} {...props} />);

const AvisoConfirmacion = ({ open, title, text, accept, cancel, ...props }) => {
    const classes = useStyles();

    return (
        <div>
            <Dialog sx={{ zIndex: 5000 }} open={open} TransitionComponent={Transition} keepMounted>
                <form>
                    <DialogTitle>
                        <Typography variant="title">{title}</Typography>
                    </DialogTitle>
                    <Divider />
                    <DialogContent>
                        <DialogContentText>
                            <Typography variant="menuTitle"> {text} </Typography>
                        </DialogContentText>
                    </DialogContent>

                    <DialogActions>
                        <Button className={classes.cancelar} onClick={cancel}>
                            cancelar
                        </Button>
                        <Button className={classes.aceptar} onClick={accept}>
                            Aceptar
                        </Button>
                    </DialogActions>
                </form>
            </Dialog>
        </div>
    );
};

export default AvisoConfirmacion;
