import { Alert, Snackbar } from '@mui/material';
import React, { useState } from 'react';
import { Controller } from 'react-hook-form';
import { DropzoneAreaBase } from 'react-mui-dropzone';

const MyUploader = ({ defaultValue, rules, name, control, filesLimit, acceptedFiles, label }) => {
    const [mensaje, setMensaje] = useState({ open: false, value: '', estatus: 'error' });
    const cerrarMensaje = () => setMensaje({ ...mensaje, open: false });
    const handleAdd = (onChange, files) => (newFiles) => {
        onChange(files.concat(newFiles));
    };
    const handleDelete = (onChange, files) => (deleted) => {
        onChange(files.filter((f) => f !== deleted));
    };
    return (
        <>
            <Controller
                defaultValue={defaultValue || []}
                rules={rules || {}}
                name={name}
                control={control}
                render={({ field }) => (
                    <DropzoneAreaBase
                        dropzoneText="Agregar evidencia(s)"
                        filesLimit={filesLimit}
                        acceptedFiles={acceptedFiles}
                        inputProps={{ title: 'Solo puede agregar un archivo multimedia' }}
                        maxFileSize={2147483648}
                        fileObjects={field.value}
                        onAdd={handleAdd(field.onChange, field.value)}
                        onDelete={handleDelete(field.onChange, field.value)}
                        showAlerts={[]}
                        dropzoneProps={{
                            disabled: field.value.length == 1
                        }}
                        onAlert={(message, variant) => {
                            switch (variant) {
                                case 'error':
                                    if (message.includes('File type not supported'))
                                        message = 'Formato invalido, solo se permiten formatos de tipo video';
                                    else if (message.includes('Maximum allowed number'))
                                        message = `Se excedió el número máximo de archivos, solo se permite ${filesLimit} archivo(s)`;
                                    setMensaje({ open: true, estatus: variant, value: message });
                                    break;
                                default:
                                    break;
                            }
                        }}
                    />
                )}
            />
            <Snackbar open={mensaje.open} autoHideDuration={4000} onClose={cerrarMensaje}>
                <Alert onClose={cerrarMensaje} variant="filled" severity={mensaje.estatus}>
                    {mensaje.value}
                </Alert>
            </Snackbar>
        </>
    );
};
MyUploader.defaultProps = {
    filesLimit: 1,
    acceptedFiles: ['video/*']
};

export default MyUploader;
