import { useContextServicios } from 'Context/ContextServicios';

const ServiciosAdmTer = () => {
    const { llamarServidorRespuesta } = useContextServicios();
    return {
        getProy: llamarServidorRespuesta('PadmViv/get_proy', false),
        getNeoCli: llamarServidorRespuesta('PadmViv/get_neoCli'),
        getAdmViv: llamarServidorRespuesta('PadmViv/get_admVivCli', false),
        putAdmViv: llamarServidorRespuesta('PadmViv/put_admVivCli'),
        updAdmViv: llamarServidorRespuesta('PadmViv/update_admVivCli'),
        delAdmViv: llamarServidorRespuesta('PadmViv/delete_admVivCli')
    };
};
export default ServiciosAdmTer;
