// material-ui
import { CalendarMonth, Info, PictureAsPdf } from '@mui/icons-material';
import { Alert, AlertTitle, Grid, Box, FormControlLabel, Button, CircularProgress, Typography } from '@mui/material';
import { styled } from '@mui/styles';
import { useLogin } from 'Context/ContextLogin';
import { useForm } from 'react-hook-form';
import { TextFieldAutoComplete, ModalGeneral } from 'ui-component';
// project imports
import MainCard from 'ui-component/cards/MainCard';
import useServiciosFacturas from 'utils/ServiciosFacturas';
import { saveAs } from 'file-saver';
import { useEffect, useState } from 'react';
import InputLabel from '../../ui-component/inputlabel';

const CssFormControlLabel = styled(FormControlLabel)({
    '& .MuiTypography-root': {
        width: '100%'
    }
});
// ==============================|| SAMPLE PAGE ||============================== //

const EstadoCuenta = () => {
    const {
        register,
        control,
        handleSubmit,
        formState: { errors }
    } = useForm();
    const ServiciosFacturas = useServiciosFacturas();
    const [viviendas, setViviendas] = useState([]);

    const [modalForm, setModalForm] = useState({ open: false, Formulario: null, props: null });
    const cerrarModalForm = () => {
        setModalForm({ ...modalForm, open: false });
    };

    const { login } = useLogin();

    // USAMOS USE EFECT CUANDO CAMBIE LOGIN
    useEffect(() => {
        // VALIDAMOS QUE LOGIN Y LOGIN.LOTES EXISTA
        if (login && login.lotes) {
            // COLOCAMOS LOS VALORES EN EL FORMATO NECESARIO PARA EL TEXTFIELDAUTOCOMPLETE

            const mapRes = login.lotes.map((item) => ({
                idVivienda: item.idCobranza,
                nVivienda: `${item.vivienda}-${item.tipoLote}`
            }));
            // COLOCAMOS LOS VALORES EL VIVIENDAS
            setViviendas(mapRes);
        }
    }, [login]);

    const clickGenerar = (event) => {
        // VALIDAMOS SI EL VALOR DEL FORMULARIO ES DIFERENTE DE NULL

        if (event.vivienda != 1) {
            const idVivienda = event.vivienda.idVivienda;
            if (idVivienda != '') {
                setModalForm({
                    open: true,
                    titulo: 'Generando documento',
                    width: 'sm'
                });

                ServiciosFacturas.dwn_estadoV2(
                    (facturas) => {
                        cerrarModalForm();
                        const binaryString = window.atob(facturas.pdf);
                        const binaryLen = binaryString.length;
                        const bytes = new Uint8Array(binaryLen);

                        for (let i = 0; i < binaryLen; i += 1) {
                            const ascii = binaryString.charCodeAt(i);
                            bytes[i] = ascii;
                        }

                        const blob1 = new Blob([bytes], { type: 'application/pdf' });
                        saveAs(blob1, `${facturas.nombre}.pdf`);
                    },
                    {
                        vivienda: idVivienda
                    },
                    (error) => {
                        cerrarModalForm();
                    }
                );
            } else {
                window.alert('El terreno no cuenta con una identificación válida');
            }
        }
    };
    return (
        <MainCard title="ESTADO DE CUENTA" className="bg-gray">
            <Grid container className="mainContainer">
                {/* EL TAMAÑO DE MAINCONTENT EN EL 100% Y CARDCONTAINER PUEDE AJUSTARSE AL TAMAÑO QUE SE NECESITA */}
                <Grid item xs={12} md={7} className="cardContainer">
                    <Grid className="body">
                        <form onSubmit={handleSubmit(clickGenerar)}>
                            <Grid container spacing={2} justifyContent="center">
                                {/* <Grid item xs={6}>
                                    <Alert
                                        icon={<Info fontSize="inherit" style={{ color: 'white' }} />}
                                        severity="info"
                                        style={{
                                            textAlign: 'center',
                                            backgroundColor: '#6E94B5'
                                        }}
                                    >
                                        Consulta las facturas de tu lote
                                    </Alert>
                                </Grid> */}
                                <Grid item xs={10}>
                                    <InputLabel
                                        // label="Selecciona un registro" // INFORMACIÓN DEL INPUT
                                        // titleTooltip="hola" // PARA MOSTRAR EL MENSAJE EN TOOLTIP
                                        error={errors.vivienda} // PARA APLICAR ERRORES EN LABEL Y TOOLTIP (SI APLICA)
                                        input={
                                            <TextFieldAutoComplete
                                                control={control}
                                                name="vivienda"
                                                label="Selecciona el terreno a consultar*"
                                                rules={{ required: { value: true, message: 'Campo requerido' } }}
                                                options={viviendas}
                                                optionSelected={{ id: 'idVivienda', label: 'nVivienda' }}
                                            />
                                        } // SE ENVIA EL ELEMENTO Y SE MUESTRA EN EL COMPONENTE
                                        style={{ marginTop: '20px' }}
                                    />
                                </Grid>
                                {/* <Grid className="container-button" item xs={12}>
                                    <Box className="field">
                                        <Box
                                            className="field-label"
                                            style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}
                                        >
                                            <Tooltip
                                                placement="left"
                                                title="Hola desde tooltip"
                                                style={{
                                                    display: 'flex',
                                                    alignItems: 'center',
                                                    justifyContent: 'center',
                                                    height: '35px',
                                                    width: '20px',
                                                    color: '#ffffff',
                                                    backgroundColor: '#979797',
                                                    borderRadius: '8px 0px 0px 8px',
                                                    fontFamily: 'emoji',
                                                    fontSize: '20pt'
                                                }}
                                            >
                                                i
                                            </Tooltip>
                                            <Typography
                                                style={{
                                                    marginLeft: '5px',
                                                    fontFamily: '"Relaway", sans-serif',
                                                    fontSize: '28pt',
                                                    color: '#0E2439'
                                                }}
                                            >
                                                Terreno
                                            </Typography>
                                        </Box>
                                        <TextFieldAutoComplete
                                            control={control}
                                            name="vivienda"
                                            label="Selecciona el terreno a consultar*"
                                            rules={{ required: { value: true, message: 'Campo requerido' } }}
                                            options={viviendas}
                                            optionSelected={{ id: 'idVivienda', label: 'nVivienda' }}
                                        />
                                    </Box>
                                </Grid> */}
                                <Grid item xs={12}>
                                    {/* <FormControl fullWidth>
                                        <RadioGroup {...register('tipoLote', { required: { value: true, message: 'Campo requerido' } })}>
                                            <FormLabel>Otros estados de cuenta</FormLabel>
                                            <FormControlLabel
                                                value="men"
                                                control={<Radio />}
                                                label="Estado de cuenta desde el inicio del crédito al día de hoy."
                                            />
                                            <FormLabel>Estado de cuenta por mes/año</FormLabel>
                                            <CssFormControlLabel
                                                value="man"
                                                style={{ width: '100%' }}
                                                control={<Radio />}
                                                label={
                                                    <Grid container spacing={2}>
                                                        <Grid item xs>
                                                            <TextField
                                                                fullWidth
                                                                label="Mes"
                                                                select
                                                                SelectProps={{ native: true }}
                                                                error={Boolean(errors?.mes)}
                                                                helperText={errors?.mes?.message}
                                                                size="small"
                                                                {...register('mes', {
                                                                    required: { value: true, message: 'Campo requerido' }
                                                                })}
                                                                InputProps={{
                                                                    endAdornment: (
                                                                        <InputAdornment>
                                                                            <CalendarMonth />
                                                                        </InputAdornment>
                                                                    )
                                                                }}
                                                            >
                                                                <option />
                                                                <option>Enero</option>
                                                            </TextField>
                                                        </Grid>
                                                        <Grid item xs>
                                                            <TextField
                                                                fullWidth
                                                                label="Año"
                                                                select
                                                                SelectProps={{ native: true }}
                                                                error={Boolean(errors?.mes)}
                                                                helperText={errors?.mes?.message}
                                                                size="small"
                                                                {...register('mes', {
                                                                    required: { value: true, message: 'Campo requerido' }
                                                                })}
                                                                InputProps={{
                                                                    endAdornment: (
                                                                        <InputAdornment>
                                                                            <CalendarMonth />
                                                                        </InputAdornment>
                                                                    )
                                                                }}
                                                            >
                                                                <option />
                                                                <option>2024</option>
                                                            </TextField>
                                                        </Grid>
                                                    </Grid>
                                                }
                                            />
                                        </RadioGroup>
                                    </FormControl> */}
                                </Grid>
                                <Grid item xs={4}>
                                    <Button
                                        id="btn-generar-pdf"
                                        className="btn-info"
                                        variant="contained"
                                        size="medium"
                                        fullWidth
                                        type="submit"
                                    >
                                        Enviar
                                    </Button>
                                </Grid>
                            </Grid>
                        </form>
                    </Grid>
                </Grid>
            </Grid>
            <ModalGeneral open={modalForm.open} width={modalForm.width} titulo={modalForm.titulo} ContenidoMensaje={modalForm.mensaje}>
                <Grid container spacing={2} sx={{ paddingLeft: '20px' }}>
                    <Grid item xs={12} sx={{ display: 'flex', alignItems: 'center' }}>
                        <Typography>El proceso de creación puede tomar unos minutos.</Typography>
                        <CircularProgress color="inherit" sx={{ padding: '10px', margin: '10px' }} />
                    </Grid>
                </Grid>
            </ModalGeneral>
        </MainCard>
    );
};

export default EstadoCuenta;
