import PropTypes from 'prop-types';
import { useNavigate } from 'react-router-dom';

// material-ui
import { useTheme } from '@mui/material/styles';
import { makeStyles } from '@mui/styles';
import { Grid, Box, ButtonBase, Tooltip } from '@mui/material';

// project imports
import LogoSection from '../LogoSection';
import SearchSection from './SearchSection';
import ProfileSection from './ProfileSection';
import NotificationSection from './NotificationSection';

// assets
import { IconMenu2, IconSettings, IconLogout } from '@tabler/icons';
import { ReactComponent as IconMenu } from 'assets/images/icons/menu.svg';
import { ReactComponent as IconEngrane } from 'assets/images/icons/engrane.svg';
import { ReactComponent as IconSalir } from 'assets/images/icons/salir.svg';
import useServicioLogin from 'utils/ServiciosLogin';
import { useLogin } from 'Context/ContextLogin';
import { Home, Logout } from '@mui/icons-material';
import logoOut from 'assets/images/logout-icon.svg';
import logoHome from 'assets/images/home-icon.svg';
import logoBars from 'assets/images/bars-icon.svg';
import logoCierra from 'assets/images/cerrar_icon.svg';
import { borderRadius, width } from '@mui/system';

// ==============================|| MAIN NAVBAR / HEADER ||============================== //

const useStyles = makeStyles((theme) => ({
    root: {
        width: '100%',
        maxWidth: 360,
        backgroundColor: theme.palette.background.paper
    },
    iconDefault: {
        width: '1.8rem',
        fill: theme.palette.primary.dark,
        '&:hover': {
            fill: theme.palette.dark.main,
            color: theme.palette.red.main
        }
    },
    iconCierra: {
        width: '15px',
        height: '15px',
        fill: theme.palette.primary.dark,
        '&:hover': {
            fill: theme.palette.dark.main,
            color: theme.palette.red.main
        }
    },
    iconHome: {
        width: '30px',
        margin: '20px',
        fill: theme.palette.primary.dark,
        '&:hover': {
            fill: theme.palette.dark.main,
            color: theme.palette.red.main
        }
    },
    iconSalir: {
        width: '30px',
        margin: '20px',
        fill: theme.palette.primary.dark,
        '&:hover': {
            fill: theme.palette.red.main,
            color: theme.palette.red.main
        }
    }
}));

const Header = ({ handleLeftDrawerToggle, drawerOpen }) => {
    const theme = useTheme();
    const classes = useStyles();
    const serviciosLogin = useServicioLogin();
    const { login, cerrarLogin } = useLogin();
    const navigate = useNavigate();
    const handleLogout = async () => {
        // cerrarSesion(() => cerrarLogin());
        serviciosLogin.cerrarSesion(
            (data) => {
                cerrarLogin();
            },
            { token: login.token }
        );
    };

    const handleListItemClick = (event, index, route = '') => {
        if (route && route !== '') {
            navigate(route);
        }
    };

    const IconTop = (props) => {
        const { icon, onClick, tooltip, borderRadius } = props;
        return (
            <Tooltip title={tooltip} disableInteractive>
                <ButtonBase sx={{ overflow: 'hidden' }} borderRadius={borderRadius || '12px'} onClick={onClick}>
                    {icon}
                </ButtonBase>
            </Tooltip>
        );
    };

    return (
        <>
            <Box sx={{ display: 'flex', justifyContent: 'flex-start', width: '90%' }}>
                <IconTop
                    sx={{ display: { xs: 'block', md: 'block', lg: 'none' } }}
                    icon={
                        <img
                            src={!drawerOpen ? logoBars : logoCierra}
                            alt="Berry"
                            className={!drawerOpen ? classes.iconDefault : classes.iconCierra}
                        />
                    }
                    tooltip="Menú"
                    onClick={handleLeftDrawerToggle}
                />
                <Box component="span" sx={{ marginLeft: { xs: '15px', md: '30px' } }}>
                    <LogoSection />
                </Box>
            </Box>
            <Grid container spacing={2} sx={{ display: 'flex', justifyContent: 'space-between' }}>
                <Grid item xs={12} sx={{ display: 'flex', alignItems: 'center', justifyContent: 'flex-end' }}>
                    <Box
                        sx={{
                            maxWidth: {
                                sm: '240px',
                                md: 'inherit'
                            },
                            display: { xs: 'none', sm: 'block' }
                        }}
                    >
                        <ProfileSection style={{ margin: '20px', width: '100%' }} />
                    </Box>
                    <IconTop
                        icon={<img src={logoHome} alt="Berry" className={classes.iconHome} />}
                        tooltip="Inicio"
                        onClick={(event) => handleListItemClick(event, 0, '/')}
                    />
                    {/* <IconTop
                        icon={<IconEngrane stroke={1.5} className={classes.iconDefault} />}
                        tooltip="Configuración de perfil"
                        onClick={(event) => handleListItemClick(event, 0, '/perfil')}
                    /> */}
                    <Box sx={{ display: { xs: 'none', sm: 'block' } }}>
                        <IconTop
                            icon={<img src={logoOut} alt="Berry" className={classes.iconSalir} />}
                            tooltip="Cerrar sesión"
                            onClick={handleLogout}
                        />
                    </Box>
                </Grid>
            </Grid>
            {/* BARRA DE USUARIO EN PARTE INFERIOR */}
            <Box
                sx={{ display: { xs: 'none', sm: 'none!important' } }}
                style={{
                    height: 'auto',
                    width: '100%',
                    position: 'fixed',
                    top: '95px',
                    left: '0px',
                    backgroundColor: 'rgb(244,244,244, 0.93)',
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    padding: '10px'
                }}
            >
                <ProfileSection />
            </Box>
            {/* header search <SearchSection /> */}
            {/* <Box sx={{ flexGrow: 1 }} />
            <Box sx={{ flexGrow: 1 }} /> */}

            {/* notification & profile <NotificationSection /> */}
        </>
    );
};

Header.propTypes = {
    handleLeftDrawerToggle: PropTypes.func
};

export default Header;
