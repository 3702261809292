// Settings Context - src/context/Settings
import { Backdrop, CircularProgress, Snackbar, Alert, Typography, Stack } from '@mui/material';
import { makeStyles } from '@mui/styles';
import axios from 'axios';
import React, { useContext, useState } from 'react';
import { useLogin } from './ContextLogin';
import { ModalFormularioV2 } from 'ui-component';

const useStyle = makeStyles((theme) => ({
    cargando: {
        zIndex: '1600'
    },
    cargandoDialog: {
        color: '#fff',
        zIndex: theme.zIndex.drawer + 1
    }
}));
const iaxios = axios.create({
    baseURL: 'https://api-cobranza.gphsis.com/index.php/'
});
const ServiciosContext = React.createContext();

export const ServiciosProvider = ({ children }) => {
    const classes = useStyle();
    const { login, cerrarLogin } = useLogin();
    const [cargando, setCargando] = useState(false);
    const [mensaje, setMensaje] = useState({ open: false, status: 0, value: '' });
    const [muestraDialogoExtra, setDialogoExtra] = useState(false);
    const cerrarMensaje = (event) => setMensaje({ ...mensaje, open: false });
    const [modalFormulario, setModalFormulario] = useState({ open: false, formulario: null, props: null });
    iaxios.defaults.headers.common = { authorization: login?.token };
    const cerrarModalForm = (event) => setModalFormulario({ ...modalFormulario, open: false });
    const llamarServidorRespuesta =
        (ruta, showMensaje = true, showDialogExtra = false) =>
        (onSuccess, body, onError) => {
            setCargando(true);
            setDialogoExtra(showDialogExtra);
            iaxios
                .post(ruta, body)
                .then((response) => {
                    let mensaje = 'ERROR DESCONOCIDO';
                    let status = -1;
                    setCargando(false);
                    if (response.status === 200 && response.data.status != undefined) {
                        if (response.data.status > 0) {
                            setModalFormulario((mdl) => (mdl.open && showMensaje ? { ...mdl, open: false } : mdl));
                            onSuccess(response.data.data);
                        } else if (onError) {
                            onError(response);
                        }
                        status = response.data.status;
                        mensaje = response.data.mensaje;
                    }
                    if (showMensaje) setMensaje({ ...mensaje, open: true, status: status, value: mensaje });
                })
                .catch((error) => {
                    if (error && error.response.status == 403) {
                        cerrarLogin();
                        setMensaje({ ...mensaje, open: true, status: -1, value: 'Sesión caducada' });
                    } else if (error) {
                        setMensaje({ ...mensaje, open: true, status: -1, value: 'Error de servidor' });
                        if (onError) {
                            onError(error);
                        }
                    }
                    setCargando(false);
                });
        };

    return (
        <>
            <Backdrop className={muestraDialogoExtra ? classes.cargandoDialog : classes.cargando} open={cargando}>
                <Stack gap={1} justifyContent="center" alignItems="center">
                    <CircularProgress color="inherit" />
                    {/* <Typography>Cargando...</Typography> */}
                </Stack>
            </Backdrop>
            <ServiciosContext.Provider value={{ llamarServidorRespuesta, setModalFormulario }}>
                {children}
                <Snackbar
                    open={mensaje.open}
                    autoHideDuration={4000}
                    onClose={cerrarMensaje}
                    anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
                    style={{
                        top: '20%'
                    }}
                >
                    <Alert
                        onClose={cerrarMensaje}
                        variant="filled"
                        severity={(mensaje.status < 0 && 'error') || (mensaje.status > 0 && 'success') || 'info'}
                    >
                        {String(mensaje.value)}
                    </Alert>
                </Snackbar>
                <ModalFormularioV2
                    titulo={modalFormulario.titulo}
                    open={modalFormulario.open}
                    isVer={modalFormulario.isVer}
                    mensajeConfirmacion={modalFormulario.mensajeConfirmacion}
                    bloqueo={cargando}
                    width={modalFormulario.width}
                    OnCancelar={cerrarModalForm}
                    aceptar={modalFormulario.aceptar}
                    setError={modalFormulario.setError}
                    btnAceptarName={modalFormulario?.btnAceptarName}
                    btnCancelarName={modalFormulario?.btnCancelarName}
                >
                    {modalFormulario.formulario && <modalFormulario.formulario {...modalFormulario.props} />}
                </ModalFormularioV2>
            </ServiciosContext.Provider>
        </>
    );
};

export const useContextServicios = () => {
    const servicios = useContext(ServiciosContext);
    return { llamarServidorRespuesta: servicios.llamarServidorRespuesta };
};
export const useContextFormulario = () => {
    const { setModalFormulario } = useContext(ServiciosContext);
    const abrirFormulario = (title, formulario, props, aceptar, config = {}) =>
        setModalFormulario({ open: true, title, formulario, props, aceptar, ...config });
    return { abrirFormulario };
};

export default ServiciosContext;
