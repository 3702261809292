import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, colors, Grid } from '@mui/material';
import { FormProvider, useForm } from 'react-hook-form';
import { makeStyles, styled } from '@mui/styles';

const useStyles = makeStyles((theme) => ({}));

const ModalFormulario = (props) => {
    const {
        open,
        titulo,
        ContenidoMensaje,
        OnCancelar,
        OnCerrar,
        width,
        bloqueo,
        aceptar,
        children,
        mensajeConfirmacion,
        isVer,
        btnCancelarName,
        btnAceptarName,
        setError
    } = props;
    const methods = useForm();
    const [mAdvertencia, setMAdvertencia] = useState({ open: false, data: null });
    const cerrarAdvertencia = () => setMAdvertencia({ open: false, data: null });
    const abrirAdvertencia = (data) => setMAdvertencia({ open: true, data: data });
    const clickConfimar = (data) => {
        aceptar(data);
    };

    useEffect(() => {
        if (!open) {
            if (mAdvertencia.open) cerrarAdvertencia();
        } else methods.reset();
    }, [open]);
    useEffect(() => {
        if (setError) {
            Object.keys(setError).forEach((key) => {
                methods.setError(key, { type: 'custom', message: setError[key] });
            });
            cerrarAdvertencia();
        }
    }, [setError]);
    const Acctions = () => {
        if (isVer) {
            return (
                <Grid container justifyContent="center">
                    <Grid item>
                        <Button variant="contained" onClick={OnCancelar}>
                            Cerrar
                        </Button>
                    </Grid>
                </Grid>
            );
        }
        return (
            <Grid container spacing={2} justifyContent="center">
                <Grid item>
                    <Button variant="contained" disabled={bloqueo} onClick={OnCancelar}>
                        {btnCancelarName}
                    </Button>
                </Grid>
                <Grid item>
                    <Button variant="contained" type="submit" disabled={bloqueo}>
                        {btnAceptarName}
                    </Button>
                </Grid>
            </Grid>
        );
    };

    return (
        <>
            <Dialog open={open} onClose={OnCerrar} scroll="body" fullWidth maxWidth={width} aria-labelledby="form-dialog-title">
                <DialogTitle id="form-dialog-title">{titulo}</DialogTitle>
                <FormProvider {...methods}>
                    <form
                        autoComplete="off"
                        onSubmit={methods.handleSubmit(mensajeConfirmacion !== false ? abrirAdvertencia : clickConfimar)}
                    >
                        <DialogContent>
                            {ContenidoMensaje && <DialogContentText>{ContenidoMensaje}</DialogContentText>}
                            {children}
                        </DialogContent>
                        <DialogActions>
                            <Acctions />
                        </DialogActions>
                    </form>
                </FormProvider>
            </Dialog>
            <Dialog open={mAdvertencia.open} onClose={cerrarAdvertencia} fullWidth maxWidth="sm">
                <DialogTitle title="Adevertencia" />

                <DialogContent>
                    <DialogContentText>{mensajeConfirmacion}</DialogContentText>
                </DialogContent>
                <DialogActions
                    style={{
                        justifyContent: 'center'
                    }}
                >
                    <Button variant="contained" onClick={cerrarAdvertencia}>
                        Regresar
                    </Button>
                    <Button variant="contained" disabled={bloqueo} onClick={() => clickConfimar(mAdvertencia.data)}>
                        Confirmar
                    </Button>
                </DialogActions>
            </Dialog>
        </>
    );
};
ModalFormulario.propTypes = {
    open: PropTypes.bool.isRequired,
    titulo: PropTypes.string.isRequired,
    ContenidoMensaje: PropTypes.string,
    OnCancelar: PropTypes.func.isRequired,
    children: PropTypes.node,
    aceptar: PropTypes.func.isRequired,
    width: PropTypes.string,
    bloqueo: PropTypes.bool,
    mensajeConfirmacion: PropTypes.string,
    isVer: PropTypes.bool,
    btnCancelarName: PropTypes.string,
    btnAceptarName: PropTypes.string,
    setError: PropTypes.string
};
ModalFormulario.defaultProps = {
    width: 'sm',
    bloqueo: false,
    mensajeConfirmacion: 'Asegúrese de que todos los campos sean correctos, ¿Está seguro de continuar?',
    isVer: false,
    btnCancelarName: 'Cancelar',
    btnAceptarName: 'Aceptar'
};
export default ModalFormulario;
