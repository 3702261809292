import { Expand, ExpandLess } from '@mui/icons-material';
import { Accordion, AccordionDetails, AccordionSummary, Button, Divider, Grid, TextField, Typography } from '@mui/material';
import { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import AvisoConfirmacion from 'ui-component/AvisoConfirmacion';
import MainCard from 'ui-component/cards/MainCard';
import SubCard from 'ui-component/cards/SubCard';
import SubCardCollapase from 'ui-component/cards/SubCardCollapase';
import ServiciosPerfil from './Components/servicesPerfil';

const PerfilUsuario = ({ usuario, permisos }) => {
    const servicio = ServiciosPerfil();
    const {
        register,
        handleSubmit,
        getValues,
        formState: { errors }
    } = useForm();
    const [confirmacion, setConfirmacion] = useState({ open: false, data: null });
    const [usuarioDetalle, setUsuarioDetalle] = useState({});
    const cambiarContrasena = (data) => {
        setConfirmacion({ open: true, data: data });
    };
    const cerrarConfimacion = () => setConfirmacion({ open: false, data: null });
    const aceptarConfirmacion = (event) => {
        servicio.cambiarMiContrasena((event) => {
            cerrarConfimacion();
        }, confirmacion.data);
    };
    useEffect(() => {
        // servicio.getDatosPerfil((data) => {
        //     // setUsuarioDetalle(data);
        //     console.log(data);
        // }, {});
    }, []);
    return (
        <>
            <MainCard title="Mi perfil">
                <Grid container spacing={2}>
                    <Grid item xs={3}>
                        <Typography variant="caption">Usuario</Typography>
                        <br />
                        <Typography variant="button">{usuarioDetalle.usuario}</Typography>
                    </Grid>
                    <Grid item xs={3}>
                        <Typography variant="caption">Rol</Typography>
                        <br />
                        <Typography variant="button">{usuarioDetalle.nrol}</Typography>
                    </Grid>
                    <Grid item xs={6}>
                        <Typography variant="caption">Nombre</Typography>
                        <br />
                        <Typography variant="button">{usuarioDetalle.nombreUsuario}</Typography>
                    </Grid>
                    <Grid item xs={6}>
                        <Typography variant="caption">Correo</Typography>
                        <br />
                        <Typography variant="button">{usuarioDetalle.correo}</Typography>
                    </Grid>
                    <Grid item xs={6}>
                        <Typography variant="caption">Telefono</Typography>
                        <br />
                        <Typography variant="button">{usuarioDetalle.telefono}</Typography>
                    </Grid>
                    <Grid item xs={3}>
                        <Typography variant="caption">Activo desde</Typography>
                        <br />
                        <Typography variant="button">{usuarioDetalle.fcrea || '---'}</Typography>
                    </Grid>
                    <Grid item xs={3}>
                        <Typography variant="caption">Primer acceso</Typography>
                        <br />
                        <Typography variant="button">{usuarioDetalle.primerConeccion}</Typography>
                    </Grid>
                    <Grid item xs={3}>
                        <Typography variant="caption">Último acceso</Typography>
                        <br />
                        <Typography variant="button">{usuarioDetalle.ultimaConeccion}</Typography>
                    </Grid>
                </Grid>
            </MainCard>
            <br />
            {permisos.cambiarContrasena && (
                <SubCardCollapase title="Cambio de contraseña">
                    <form onSubmit={handleSubmit(cambiarContrasena)}>
                        <Grid container spacing={2}>
                            <Grid item xs={3}>
                                <TextField
                                    label="Constraseña actual"
                                    size="small"
                                    fullWidth
                                    type="password"
                                    {...register('contrasenaActual', { required: { value: true, message: 'Campo requerido' } })}
                                    error={Boolean(errors.contrasenaActual)}
                                    helperText={errors.contrasenaActual?.message}
                                />
                            </Grid>
                            <Grid item xs={3}>
                                <TextField
                                    label="Constraseña"
                                    size="small"
                                    fullWidth
                                    type="password"
                                    {...register('contrasena1', { required: { value: true, message: 'Campo requerido' } })}
                                    error={Boolean(errors.contrasena1)}
                                    helperText={errors.contrasena1?.message}
                                />
                            </Grid>
                            <Grid item xs={3}>
                                <TextField
                                    label="Confirmar Constraseña"
                                    size="small"
                                    type="password"
                                    fullWidth
                                    {...register('contrasena2', {
                                        required: {
                                            value: true,
                                            message: 'Campo requerido'
                                        },
                                        validate: (value) => value == getValues('contrasena1') || 'Las contraseñas no coinciden'
                                    })}
                                    error={Boolean(errors.contrasena2)}
                                    helperText={errors.contrasena2?.message}
                                />
                            </Grid>
                            <Grid item xs={3}>
                                <Button variant="contained" type="submit">
                                    Guardar
                                </Button>
                            </Grid>
                        </Grid>
                    </form>
                </SubCardCollapase>
            )}
            <AvisoConfirmacion
                open={confirmacion.open}
                title="Advertencia"
                text="¿Estas seguro de cambiar tu contraseña? Asegúrese de recordar la nueva contraseña antes de aceptar."
                accept={aceptarConfirmacion}
                cancel={cerrarConfimacion}
            />
        </>
    );
};
export default PerfilUsuario;
