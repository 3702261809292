import React from 'react';
import { makeStyles } from '@mui/styles';
import PropTypes from 'prop-types';
import {
    Button,
    TextField,
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle,
    colors,
    IconButton,
    Container,
    Typography,
    Grid
} from '@mui/material';
import { Close } from '@mui/icons-material';

const useStyles = makeStyles((theme) => ({
    cancelar: {
        color: theme.palette.btn.red,
        '&:hover': {
            backgroundColor: theme.palette.btn.red,
            color: theme.palette.btn.white
        }
    },
    aceptar: {
        color: theme.palette.btn.green,
        '&:hover': {
            backgroundColor: theme.palette.btn.green,
            color: theme.palette.btn.white
        }
    },
    marginTitle: {
        marginTop: '12px'
    }
}));
const FormConfirmacion = (props) => {
    const { open, titulo, ContenidoMensaje, OnCerrar, width, acciones, children, paddingDisabled } = props;
    const classes = useStyles();
    return (
        <Dialog open={open} fullWidth maxWidth={width} scroll="body" aria-labelledby="form-dialog-title">
            <DialogTitle>
                <Grid container item xs={12}>
                    <Grid item xs={11}>
                        {titulo && (
                            <Typography variant="h4" className={classes.marginTitle}>
                                {titulo}
                            </Typography>
                        )}
                    </Grid>
                    <Grid item xs={1} style={{ textAlign: 'end' }}>
                        {OnCerrar && (
                            <IconButton aria-label="close" onClick={OnCerrar} style={{ color: (theme) => theme.palette.error }}>
                                <Close />
                            </IconButton>
                        )}
                    </Grid>
                </Grid>
            </DialogTitle>
            <Container className="MuiTypography-root MuiDialogContentText-root MuiTypography-body1 MuiTypography-colorTextSecondary">
                {ContenidoMensaje}
            </Container>

            {children}
            {acciones && <DialogActions>{acciones}</DialogActions>}
        </Dialog>
    );
};
FormConfirmacion.propTypes = {
    open: PropTypes.bool.isRequired,
    titulo: PropTypes.string,
    ContenidoMensaje: PropTypes.string,
    acciones: PropTypes.element,
    paddingDisabled: PropTypes.bool,
    width: PropTypes.string
};
FormConfirmacion.defaultProps = {
    width: 'sm',
    paddingDisabled: false
};
export default FormConfirmacion;
