// assets
import { IconKey, IconUsers, IconBoxMultiple } from '@tabler/icons';
import { ReactComponent as IconHome } from 'assets/images/icons/ga-home.svg';
import { ReactComponent as IconCalificacion } from 'assets/images/icons/g-calificaciones.svg';
import { ReactComponent as IconDashboard } from 'assets/images/icons/g-dashboard.svg';
import { ReactComponent as IconEngrane } from 'assets/images/icons/engrane.svg';
import AssignmentIcon from '@mui/icons-material/Assignment';

import { ReactComponent as Preguntas } from 'assets/images/preguntas_frecuentes.svg';
import { ReactComponent as Terminos } from 'assets/images/terminos_condiciones.svg';
import { ReactComponent as Salir } from 'assets/images/logout-side-icon.svg';

// constant
const icons = {
    preFre: Preguntas,
    termCon: Terminos,
    logOut: Salir
    // tra: IconKey,
    // ctl: IconBoxMultiple,
    // homeIni: IconHome,
    // clfClb: IconCalificacion,
    // das: IconDashboard,
    // config: IconEngrane,
    // rep: AssignmentIcon
};

// ==============================|| EXTRA PAGES MENU ITEMS ||============================== //

const pages = (rutas) => {
    const paginas = [];
    rutas.forEach((pagina) => {
        if (pagina.idPadre) {
            const indicePagina = paginas.map((pagina) => pagina.id).indexOf(pagina.idPadre);
            if (indicePagina >= 0)
                paginas[indicePagina].children.push({
                    id: pagina.idpagina,
                    title: pagina.npagina,
                    type: 'item',
                    icon: icons[pagina.idpagina],
                    url: pagina.raiz === '0' ? pagina.rutaPadre + pagina.ruta : '/',
                    funcion: pagina.funcion ? pagina.funcion : null
                });
            else
                paginas.push({
                    id: pagina.idPadre,
                    title: pagina.ncategoria,
                    type: 'collapse',
                    icon: icons[pagina.idPadre],
                    children: [
                        {
                            id: pagina.idpagina,
                            title: pagina.npagina,
                            type: 'item',
                            icon: icons[pagina.idpagina],
                            url: pagina.raiz === '0' ? pagina.rutaPadre + pagina.ruta : '/',
                            funcion: pagina.funcion ? pagina.funcion : null
                        }
                    ]
                });
        } else
            paginas.push({
                id: pagina.idpagina,
                title: pagina.npagina,
                type: 'item',
                icon: icons[pagina.idpagina],
                url: pagina.raiz === '0' ? pagina.ruta : '/',
                breadcrumbs: true,
                funcion: pagina.funcion ? pagina.funcion : null
            });
    });
    return {
        id: 'pages',
        // title: 'Menú',
        caption: '',
        type: 'group',
        children: paginas
    };
};

export default pages;
