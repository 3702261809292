// material-ui
import { Payment } from '@mui/icons-material';
import { Button, FormControl, FormControlLabel, Grid, InputLabel, MenuItem, Radio, Select, TextField } from '@mui/material';
import { useLogin } from 'Context/ContextLogin';
import axios from 'axios';
import { useRef } from 'react';
import { useForm } from 'react-hook-form';
import { register } from 'serviceWorker';
import {
    FormatNumber,
    GroupRadioButtonForm,
    TextFieldAutoComplete,
    TextFieldFormato,
    TextFieldNumber,
    TextFieldSelectImage
} from 'ui-component';

import SubCard from 'ui-component/cards/SubCard';

const BuscarLote = ({ infoLote, fechaActual, actualizarTerreno }) => {
    const formPago = useForm();
    const refFormularioBanco = useRef(null);
    const { login } = useLogin();
    return (
        <SubCard title="Consulta tu información">
            <form
                onSubmit={formPago.handleSubmit((data) => {
                    formPago.setValue('minCapital', '0');
                    actualizarTerreno(data.lote ? data.lote : { plan: [] });
                })}
            >
                <Grid container spacing={2} justifyContent="center" alignItems="center">
                    <Grid item xs={12} md={4}>
                        <TextFieldAutoComplete
                            control={formPago.control}
                            name="lote"
                            label="Lote"
                            rules={{ required: { value: true, message: 'Campo requerido' } }}
                            options={login?.lotes?.filter((lote) =>
                                formPago.watch('tipoLote') == 'GPH' ? lote.baseneodata == 'GPH' : lote.baseneodata != 'GPH'
                            )}
                            optionSelected={{ id: 'id_cobranza', label: 'vivienda' }}
                        />
                    </Grid>
                    <Grid item xs={8} md={2}>
                        <Button variant="contained" className="btn" size="medium" fullWidth color="info" type="submit">
                            Consultar
                        </Button>
                    </Grid>
                </Grid>
            </form>
        </SubCard>
    );
};
export default BuscarLote;
