import { useRoutes } from 'react-router-dom';

// routes
import MainRoutes from './MainRoutes';
import AuthenticationRoutes from './AuthenticationRoutes';
import config from 'config';
import { useEffect, useState } from 'react';
import useServicioLogin from 'utils/ServiciosLogin';
import { useLogin } from 'Context/ContextLogin';

// ==============================|| ROUTING RENDER ||============================== //

export default function ThemeRoutes() {
    const { login, cambioLogin, cerrarLogin } = useLogin();
    const serviciosLogin = useServicioLogin();

    const [rutas, setRutas] = useState([]);
    useEffect(() => {
        if (login?.token && !login?.iducliente) {
            serviciosLogin.consultaSesionActiva(
                (respuesta) => {
                    setRutas(MainRoutes());
                    cambioLogin({
                        ...login,
                        ...respuesta.info,
                        lotes: respuesta.lotes
                    });
                },
                {},
                (error) => {
                    setRutas(AuthenticationRoutes);
                }
            );
        } else if (!login?.token) setRutas(AuthenticationRoutes);
    }, [login]);
    return useRoutes([rutas], config.basename);
}
