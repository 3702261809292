import { TextField } from '@mui/material';
import React from 'react';
import { Controller } from 'react-hook-form';
import PropTypes from 'prop-types';
import NumberFormat from 'react-number-format';

const TextFieldFormato = (props) => {
    const { name, label, rules, defaultValue, control, onChange } = props;
    return (
        <Controller
            defaultValue={defaultValue}
            rules={rules}
            name={name}
            control={control}
            render={({ field, fieldState: { error } }) => (
                <NumberFormat
                    format="## ####-####"
                    mask="#"
                    customInput={TextField}
                    onValueChange={(v) => {
                        field.onChange(v.value);
                        if (onChange) onChange(v.value);
                    }}
                    allowNegative={false}
                    inputRef={field.ref}
                    defaultValue={defaultValue}
                    label={label}
                    size="small"
                    fullWidth
                    error={Boolean(error)}
                    helperText={error?.message}
                />
            )}
        />
    );
};
TextFieldFormato.propTypes = {
    name: PropTypes.string.isRequired,
    rules: PropTypes.object,
    label: PropTypes.string.isRequired,
    defaultValue: PropTypes.any,
    control: PropTypes.any,
    onChange: PropTypes.func
};
export default TextFieldFormato;
