// material-ui
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { styled } from '@mui/material/styles';
import ButtonBase from '@mui/material/ButtonBase';
import { Grid, Typography } from '@mui/material';

import Carousel from 'react-material-ui-carousel';

import f1 from 'assets/images/f1.png';
import f2 from 'assets/images/f2.png';
import f3 from 'assets/images/f3.png';

import userLog from 'assets/images/user-cog-icon.svg';
import count from 'assets/images/statement-count-icon.svg';
import bills from 'assets/images/bill-icon.svg';
import cardPayment from 'assets/images/payment-card-icon.svg';

import { makeStyles } from '@mui/styles';

const useStyle = makeStyles((theme) => ({
    itemCarousel: {
        [theme.breakpoints.down('sm')]: {
            position: 'fixed',
            width: '70rem',
            height: '70%',
            maxHeight: '80vh',
            objectFit: 'fill',
            left: '-25%',
            right: '0px'
            // height: '100%'
            // width: '900px'
            // objectFit: 'contain',
            // position: 'fixed',
            // left: '-40%'
        },
        [theme.breakpoints.up('sm')]: {
            height: '100%',
            width: '100%',
            objectFit: 'cover'
        }
    }
}));

const Inicio = () => {
    const classes = useStyle();
    const navigate = useNavigate();
    const handleListItemClick = (event, index, route = '') => {
        if (route && route !== '') {
            navigate(route);
        }
    };
    const ImageButton = styled(ButtonBase)(({ theme }) => ({
        position: 'relative',
        height: '150px',
        width: '150px',
        direction: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-between',
        borderRadius: '50%',
        padding: '10px'
    }));
    const propsSlider = {
        autoPlay: true,
        swipe: false,
        indicators: false,
        interval: 4500,
        height: '80vh',
        stopAutoPlayOnHover: false,
        fullHeightHover: false
    };
    return (
        <Grid
            container
            style={{
                direction: 'row',
                justifyContent: 'center',
                alignItems: 'flex-end',
                backgroundColor: '#F6F6F6',
                height: '100%',
                width: '100%'
            }}
        >
            <Grid
                sx={{
                    marginTop: {
                        xs: '95px',
                        sm: '0px'
                    }
                }}
                style={{
                    position: 'absolute',
                    zIndex: '1',
                    top: '0px',
                    height: '100%',
                    width: '100%',
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center'
                }}
            >
                <Carousel
                    navButtonsProps={{
                        style: {
                            visibility: 'hidden'
                        }
                    }}
                    {...propsSlider}
                    sx={{ height: '100%', width: '100%' }}
                    style={{ display: 'flex', justifyContent: 'center' }}
                >
                    <img src={f1} alt="f1" className={classes.itemCarousel} />
                    <img src={f2} alt="f2" className={classes.itemCarousel} />
                    <img src={f3} alt="f3" className={classes.itemCarousel} />
                </Carousel>
                {/* */}
            </Grid>
            <Grid item className="card" xs={11} md={8} spacing={8}>
                <Grid container style={{ height: '100%', with: '100%', padding: '30px 20px 0px 20px' }}>
                    <Grid item xs={6} md={3} className="item">
                        <ImageButton
                            id="btn-terrenos"
                            className="btn-custom"
                            onClick={(event) => handleListItemClick(event, 0, '/AdministrarTerreno')}
                        >
                            <img className="btn-image" src={userLog} alt="admin" />
                            <Typography className="btn-label" component="span">
                                Administrar Terrenos
                            </Typography>
                        </ImageButton>
                    </Grid>
                    <Grid item xs={6} md={3} className="item">
                        <ImageButton
                            id="btn-estado-cuenta"
                            className="btn-custom"
                            onClick={(event) => handleListItemClick(event, 0, '/EstadoCuenta')}
                        >
                            <img className="btn-image" src={count} alt="count" />
                            <Typography className="btn-label" component="span">
                                Estado de Cuenta
                            </Typography>
                        </ImageButton>
                    </Grid>
                    <Grid item xs={6} md={3} className="item">
                        <ImageButton
                            id="btn-facturas"
                            className="btn-custom"
                            onClick={(event) => handleListItemClick(event, 0, '/Facturas')}
                        >
                            <img className="btn-image" src={bills} alt="bills" />
                            <Typography className="btn-label" component="span">
                                Facturas
                            </Typography>
                        </ImageButton>
                    </Grid>
                    <Grid item xs={6} md={3} className="item">
                        <ImageButton
                            id="btn-pagos-linea"
                            className="btn-custom"
                            onClick={(event) => handleListItemClick(event, 0, '/PagosEnlinea')}
                        >
                            <img className="btn-image" src={cardPayment} alt="paycard" />
                            <Typography className="btn-label" component="span">
                                Pago en Línea
                            </Typography>
                        </ImageButton>
                    </Grid>
                </Grid>
            </Grid>
        </Grid>
    );
};

export default Inicio;
