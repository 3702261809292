import { useContextServicios } from 'Context/ContextServicios';

const useServiciosFacturas = () => {
    const { llamarServidorRespuesta } = useContextServicios();
    return {
        GetFacturas: llamarServidorRespuesta('PanelClientes/GetFacturas', false),
        getXML: llamarServidorRespuesta('PanelClientes/getXML'),
        dwn_estadoV2: llamarServidorRespuesta('EstadoCuentaPW/dwn_estadoV2', true, true)
    };
};
export default useServiciosFacturas;
