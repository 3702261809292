// material-ui
import { Grid, TextField } from '@mui/material';
import { useFormContext } from 'react-hook-form';
import { TextFieldFormato } from 'ui-component';

const FormDatosBBVA = () => {
    const emailRegex = /^[-\w.%+]{1,64}@(?:[A-Z0-9-]{1,63}\.){1,125}[A-Z]{2,63}$/i;
    const {
        control,
        register,
        formState: { errors }
    } = useFormContext();

    return (
        <Grid container spacing={2} justifyContent="center">
            <Grid item xs={12} md={8}>
                <TextFieldFormato
                    control={control}
                    name="telefono"
                    label="Teléfono"
                    rules={{
                        required: { value: true, message: 'Campo requerido' },
                        validate: (numero) => (numero && numero.length == 10) || 'Formato incorrecto'
                    }}
                />
            </Grid>
            <Grid item xs={12} md={8}>
                <TextField
                    {...register('correo', {
                        required: { value: true, message: 'Campo requerido' },
                        validate: (correo) => (correo && emailRegex.test(correo)) || 'Formato incorrecto'
                    })}
                    label="Email"
                    fullWidth
                    size="small"
                    error={errors?.correo}
                    helperText={errors.correo?.message}
                />
            </Grid>
        </Grid>
    );
};
export default FormDatosBBVA;
