// Settings Context - src/context/Settings
import { Backdrop, CircularProgress, Snackbar, Alert } from '@mui/material';
import { makeStyles } from '@mui/styles';
import React, { useContext, useState } from 'react';

const useStyle = makeStyles(() => ({
    cargando: {
        zIndex: '900'
    }
}));
const LoginContext = React.createContext();

const Loginettings = {
    token: localStorage.getItem('token') != '' ? localStorage.getItem('token') : false
    // lotes: [
    //     {
    //         baseneodata: 'CIB',
    //         contrato: 'CMPYUC-CAOH-012',
    //         Nombre: 'FABIAN ALEJANDRO SOTO ALONSO',
    //         fechaplan: '2024-01-08',
    //         id_cobranza: '10168CIB',
    //         mensualidad: '1179.0500',
    //         referencia: '5050125',
    //         vivienda: 'CMPYUC-CAOH-012'
    //     }
    // ]
};

export const LoginProvider = ({ children, loginDefalult }) => {
    const login = useState(loginDefalult || Loginettings);

    return (
        <>
            <LoginContext.Provider value={login}>{children}</LoginContext.Provider>
        </>
    );
};

export const useLogin = () => {
    const [login, setLogin] = useContext(LoginContext);
    const cambioLogin = (values) => {
        setLogin(values);
    };
    const cerrarLogin = () => {
        localStorage.clear();
        setLogin(null);
    };

    return { login, cambioLogin, cerrarLogin };
};

export default LoginContext;
