import React from 'react';
import PropTypes from 'prop-types';
import { Controller } from 'react-hook-form';
import { Autocomplete, TextField } from '@mui/material';

const AutocompleteForm = ({ control, name, label, defaultValue, rules, error, options, optionSelected }) => (
    <Controller
        control={control}
        name={name}
        rules={rules}
        defaultValue={defaultValue || null}
        render={({ field: { onChange } }) => (
            <Autocomplete
                onChange={(event, item) => {
                    onChange(item);
                }}
                size="small"
                fullWidth
                noOptionsText="No hay coincidencias"
                options={options}
                defaultValue={defaultValue || null}
                getOptionLabel={(item) => item[optionSelected.label]}
                getOptionSelected={(option, value) =>
                    value == undefined || value === '' || option[optionSelected.id] === value[optionSelected.id]
                }
                renderInput={(params) => <TextField {...params} label={label} error={Boolean(error)} helperText={error} />}
            />
        )}
    />
);
AutocompleteForm.propTypes = {
    control: PropTypes.object.isRequired,
    name: PropTypes.string.isRequired,
    label: PropTypes.string,
    defaultValue: PropTypes.object,
    rules: PropTypes.object.isRequired,
    error: PropTypes.string,
    options: PropTypes.arrayOf(PropTypes.object).isRequired,
    optionSelected: PropTypes.shape({
        label: PropTypes.string.isRequired,
        id: PropTypes.string.isRequired
    })
};

AutocompleteForm.defaultProps = {
    label: '',
    defaultValue: null,
    optionSelected: { id: 'default', label: 'default' }
};

export default AutocompleteForm;
