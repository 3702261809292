// material-ui
import { Help, Edit, AddBox } from '@mui/icons-material';
import {
    Button,
    FormControl,
    FormControlLabel,
    FormGroup,
    FormLabel,
    Grid,
    Hidden,
    Icon,
    InputAdornment,
    Radio,
    RadioGroup,
    TextField,
    Tooltip,
    Typography
} from '@mui/material';
import { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { TextFieldAutoComplete, Tabla, ModalFormularioV2 } from 'ui-component';
import { useLogin } from 'Context/ContextLogin';

// project imports
import MainCard from 'ui-component/cards/MainCard';
import SubCard from 'ui-component/cards/SubCard';
import ServiciosAdmTer from 'utils/servicioAdmTer';
import FormTerreno from './formTerreno';
import { Box, color } from '@mui/system';
// ==============================|| SAMPLE PAGE ||============================== //

const AdministrarTerreno = () => {
    const { login, cambioLogin } = useLogin();
    const servicio = ServiciosAdmTer();
    const [listaTierra, setListaTierra] = useState([]);
    const [modalForm, setModalForm] = useState({ open: false, Formulario: null, props: null });
    useEffect(() => {
        if (login && login.lotes) {
            setListaTierra(login.lotes);
        }
    }, [login]);
    const cerrarModalForm = () => {
        setModalForm({ ...modalForm, open: false });
    };
    const nuevo = () => () => {
        setModalForm({
            open: true,
            titulo: 'CREAR REGISTRO',
            width: 'sm',
            Formulario: FormTerreno,
            aceptar: (data) => {
                data.tipoLote = 'men';
                servicio.getNeoCli(
                    (data) => {
                        const idCobranza = data[0].id_cobranza;
                        servicio.putAdmViv(
                            (response) => {
                                setListaTierra(response);
                                cambioLogin({ ...login, lotes: response });
                                setModalForm({ ...modalForm, open: false });
                            },
                            {
                                idCobranza: idCobranza,
                                idUCliente: login.iducliente
                            },
                            (error) => {
                                setModalForm({ ...modalForm, open: false });
                            }
                        );
                    },
                    {
                        idProyecto: data.proyecto.idproyecto,
                        referencia: data.referencia,
                        tipoLote: data.tipoLote
                    },
                    (error) => {
                        console.log(error);
                    }
                );
            }
        });
    };
    const eliminar = (data) => {
        setModalForm({
            open: true,
            titulo: 'ELIMINAR REGISTRO',
            width: 'sm',
            mensaje: '¿Seguro que quieres eliminar el registro?',
            mensajeConfirmacion: true,
            aceptar: () => {
                servicio.delAdmViv(
                    (response) => {
                        setListaTierra(response);
                        cambioLogin({ ...login, lotes: response });
                        setModalForm({ ...modalForm, open: false });
                    },
                    {
                        idCobranza: data.idCobranza,
                        idUCliente: login.iducliente
                    },
                    (error) => {
                        setModalForm({ ...modalForm, open: false });
                    }
                );
            }
        });
    };
    const accionesTabla = [{ label: 'AGREGAR VIVIENDA', click: nuevo, icon: <AddBox />, disabled: false, id: 'btn-agregar-vivienda' }];
    const accionesFila = [{ label: 'ELIMINAR', enabled: true, onClick: eliminar, id: 'btn-eliminar-vivienda' }];
    const columns = [
        { label: 'NOMBRE DE CLIENTE', name: 'nomCliente', filtrar: true },
        { label: 'VIVIENDA', name: 'vivienda', filtrar: true },
        { label: 'REFERENCIA', name: 'referencia', filtrar: true }
    ];
    return (
        <MainCard title="ADMINISTRAR TERRENO" className="bg-gray">
            <Grid container className="mainContainer">
                <Grid item xs={12} md={7} className="cardContainer">
                    <Grid className="header">
                        <h2>Registrar tu lote</h2>
                        <p>Favor de llenar los datos solicitados</p>
                    </Grid>
                    <Grid className="body">
                        <Tabla
                            columnKey="vivienda"
                            columns={columns}
                            rows={listaTierra}
                            accionesTabla={accionesTabla}
                            accionesFila={accionesFila}
                            accionesFilaOrientacion="right"
                        />
                    </Grid>
                </Grid>
            </Grid>
            <ModalFormularioV2
                open={modalForm.open}
                width={modalForm.width}
                titulo={modalForm.titulo}
                ContenidoMensaje={modalForm.mensaje}
                aceptar={modalForm.aceptar}
                OnCancelar={cerrarModalForm}
            >
                {modalForm.Formulario && <modalForm.Formulario {...modalForm.props} />}
            </ModalFormularioV2>
        </MainCard>
    );
};
export default AdministrarTerreno;
