import { useContextServicios } from 'Context/ContextServicios';

const useServicioLogin = () => {
    const { llamarServidorRespuesta } = useContextServicios();
    return {
        login: llamarServidorRespuesta('PCAccesos/iniciarSesion'),
        crearCuenta: llamarServidorRespuesta('PCAccesos/crearCuenta'),
        traerRutas: llamarServidorRespuesta('Usuarios/traerRutas', false),
        cerrarSesion: llamarServidorRespuesta('PCAccesos/cerrarSesion', false),
        restaurarPassword: llamarServidorRespuesta('PCAccesos/restaurarPassword'),
        solicitaCodigo: llamarServidorRespuesta('PCAccesos/solicitaCodigo'),
        buscaCodigoAcceso: llamarServidorRespuesta('PCAccesos/buscaCodigoAcceso'),
        consultaSesionActiva: llamarServidorRespuesta('PCinfoUsuario/consultaSesionActiva', false),
        confirmaCodigoRegistro: llamarServidorRespuesta('PCAccesos/confirmaCodigoRegistro'),
        buscaUsuario: llamarServidorRespuesta('PCAccesos/buscarUsuarioCorreo')
    };
};
export default useServicioLogin;
